import React from "react";
import MediaQuery from "react-responsive";
import SleepModifyCustomerInfoWeb from "./SleepModifyCustomerInfoWeb";
import SleepModifyCustomerInfoMobile from "./SleepModifyCustomerInfoMobile";
import { withRouter } from "react-router";
import { withFormik } from "formik";
import { compose } from "recompose";
import { COUNTRIES } from "config/data/countries";
import moment from "moment";

const MyForm = props => {
  const handleOpenClose = value => {
    props.setFieldValue("showConfirmation", value);
  };

  return (
    <React.Fragment>
      <MediaQuery minWidth={1025}>
        <SleepModifyCustomerInfoWeb
          pageName={props.values.pageName}
          {...props}
          handleOpenClose={handleOpenClose}
          showConfirmation={props.values.showConfirmation}
          isLoading={props.values.isLoading}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <SleepModifyCustomerInfoMobile
          {...props}
          handleOpenClose={handleOpenClose}
          showConfirmation={props.values.showConfirmation}
          isLoading={props.values.isLoading}
          isContinue={props.isContinue}
          countryOfPassport={props.countryOfPassport}
          handleCountryOfPassport={props.handleCountryOfPassport}
          countryFile={COUNTRIES}
        />
      </MediaQuery>
    </React.Fragment>
  );
};

const SleepModifyCustomerDetailForm = compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: props => {
      let phone =
        props.editData &&
        props.editData.family_friend &&
        props.editData.pic_phone
          ? props.editData.pic_phone
          : props.editData && props.editData.phone
          ? props.editData.phone
          : "";

      let dob =
        props.editData && props.editData.dob
          ? props.editData.dob.split("-")
          : null;

      let fmPhone =
        props.editData && props.editData.family_friend && props.editData.phone
          ? props.editData.phone
          : "";

      return {
        editId: props.editData && props.editData.id ? props.editData.id : "",
        fullName:
          props.editData &&
          props.editData.family_friend &&
          props.editData.pic_name
            ? props.editData.pic_name
            : props.editData &&
              props.editData.first_name &&
              props.editData.last_name
            ? `${props.editData.first_name} ${props.editData.last_name}`
            : "",
        mobileNo: phone,
        email:
          props.editData &&
          props.editData.family_friend &&
          props.editData.pic_email
            ? props.editData.pic_email
            : props.editData && props.editData.email
            ? props.editData.email
            : "",
        cc_email: "",
        dobDay: dob && dob[2] ? dob[2] : "",
        dobMonth: dob && dob[1] ? dob[1] : "",
        dobYear: dob && dob[0] ? dob[0] : "",
        family_friend: props.editData && props.editData.family_friend ? 1 : 0,
        showConfirmation: false,
        roomName:
          props.editData && props.editData.room_name
            ? props.editData.room_name
            : "",
        checkinDate:
          props.editData && props.editData.checkin_date
            ? moment(props.editData.checkin_date)
            : moment().add(1, "days"),
        checkoutDate:
          props.editData && props.editData.checkout_date
            ? moment(props.editData.checkout_date)
            : moment().add(2, "days"),
        avgWork:
          props.editData && props.editData.average_shift_worked
            ? props.editData.average_shift_worked
            : "",
        requests:
          props.editData && props.editData.requests
            ? props.editData.requests
            : "",
        family_full_name:
          props.editData &&
          props.editData.family_friend &&
          props.editData.first_name &&
          props.editData.last_name
            ? `${props.editData.first_name} ${props.editData.last_name}`
            : "",
        family_email:
          props.editData && props.editData.family_friend && props.editData.email
            ? props.editData.email
            : "",
        family_phone: fmPhone
      };
    },

    handleSubmit: (values, props) => {
      props.setFieldValue("isLoading", true);
      props.props.handleUpdateReservation(values);
    },

    displayName: "BasicForm"
  })
)(MyForm);

export default SleepModifyCustomerDetailForm;
