import React, { useState } from "react";
import "assets/scss/SleepDoorCode.scss";
import SleepImage from "./SleepImage";

const SleepCheckinConfirmation = props => {
  const [roomData] = useState(
    props.location.state && props.location.state.roomData
      ? props.location.state.roomData
      : null
  );

  return (
    <div className="SleepDoorCode" id="SleepCancellation">
      <div
        className="wrapper"
        //style={{ maxWidth: "600px", minHeight: "100vh" }}
      >
        <h2 className="title" style={{ textAlign: "center" }}>
          Your Reservation Details
        </h2>
        <p className="booking-id">
          You are staying in <span>{roomData && roomData.room_type}</span>
          <br /> in room number <span>{roomData && roomData.room_number}</span>
          <br /> The door code is{" "}
          <span>
            {roomData && roomData.door_code !== ""
              ? roomData.door_code
              : "No Code"}
          </span>
          <br /> check-in date <span>{roomData && roomData.check_in}</span>
        </p>
        <br />
        <div style={{ textAlign: "center" }}>
          <h2
            className="title"
            style={{ textAlign: "center", marginBottom: "0px" }}
          >
            You have been successfully checked-in!
          </h2>
        </div>
        <br />
        <div className="warning-text">
          <b>
            For Airstreams: A door key is available for you to lock your
            airstream if you prefer. Please use the door code above to access
            the lock box. Lock box is located inside your airstream, inside the closet.
          </b>
          <br />
          <br />
          <span className="star-text">
            **You are responsible to return the key before checking out
            <br />
            **Any lost keys must be reported to StarbaseLiving immediately
          </span>
        </div>
        {/* <h2 className="title">You’ve been successfully checked-in!</h2>
        <span>
          You will receive an email containing a detailed check-in instruction
        {</span> */}
        {roomData && roomData.room_type && <SleepImage />}
      </div>
    </div>
  );
};

export default SleepCheckinConfirmation;
