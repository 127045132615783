/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  CircularProgress,
  Select,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import PhonePrefix from "components/inputs/PhonePrefix";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  SLEEP_SINGLE_EMPLOYEE_OPTION,
  SLEEP_FAMILY_FRIEND_OPTION,
  SLEEP_FRIEND_BNB_OPTION
} from "../../../config/constants";
import MonthOptionComponent from "../../../components/inputs/MonthOptionComponent";
import DayOptionComponent from "../../../components/inputs/DayOptionComponent";
import YearOptionComponent from "../../../components/inputs/YearOptionComponent";

const SleepCustomerInfoMobile = ({
  countryFile,
  values,
  handleBlur,
  handleChange,
  isLoading,
  handleSubmit,
  errors,
  touched,
  setFieldValue,
  handleValidDate,
  errMsg,
  familyValue,
  roomArr,
  isMultiple
}) => {
  const [showCC, setShowCC] = useState(false);
  const [familyState, setFamilyState] = useState(familyValue);
  const [maxDays, setMaxDays] = useState(31);
  const handleMonthChange = value => {
    const selectedMonth = value;
    let newMaxDays;
    if (selectedMonth === "02") {
      // February
      newMaxDays = 28; // default to 28 days
      if (values.dobYear && values.dobYear % 4 === 0) {
        // Leap year
        newMaxDays = 29;
      }
    } else if (["04", "06", "09", "11"].includes(selectedMonth)) {
      // April, June, September, November
      newMaxDays = 30;
    } else {
      newMaxDays = 31;
    }
    setMaxDays(newMaxDays); // update state with new value
  };

  return (
    <React.Fragment>
      <Grid
        container
        style={{
          //position: "fixed",
          overflow: "auto",
          display: "block",
          padding: "0",
          margin: "0",
          top: "0",
          left: "0",
          width: "100%",
          height: "100vh",
          backgroundColor: "#273142"
        }}
      >
        <Grid item xs={12} className="familySelectMobile">
          <Select
            native
            value={familyState}
            onChange={e => {
              setFamilyState(e.target.value);
              e.target.value === SLEEP_FAMILY_FRIEND_OPTION
                ? setFieldValue("family_friend", 1)
                : setFieldValue("family_friend", 0);
            }}
            onBlur={handleBlur}
            inputProps={{
              className: "familySelect readonly"
            }}
            IconComponent={ExpandMoreIcon}
            disabled={true}
          >
            <option value={SLEEP_SINGLE_EMPLOYEE_OPTION}>
              {SLEEP_SINGLE_EMPLOYEE_OPTION}
            </option>
            <option value={SLEEP_FAMILY_FRIEND_OPTION}>
              {SLEEP_FAMILY_FRIEND_OPTION}
            </option>
            {/* <option value={SLEEP_FRIEND_BNB_OPTION}>
              {SLEEP_FRIEND_BNB_OPTION}
            </option> */}
          </Select>
        </Grid>
        <Grid
          item
          xs={12}
          className="contactMobileTitle"
          style={{ backgroundColor: "#273142" }}
        >
          <span
            className="title"
            style={{ paddingLeft: "15px", color: "#fff" }}
          >
            {values.family_friend === 0
              ? "Personal Information"
              : "Starbase Host"}
          </span>
        </Grid>
        {roomArr.length === 0 && (
          <>
            <Grid item xs={12} sm={12}>
              <div
                style={{
                  background: "#ffeaac",
                  textAlign: "center",
                  padding: "6px 0px",
                  marginTop: "6px",
                  borderRadius: "4px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  border: "1px solid rgb(255, 204, 0)"
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    fontSize: "32px",
                    lineHeight: "29px",
                    width: "32px",
                    height: "32px",
                    padding: "0px 20px 0px 0px",
                    color: "rgb(128, 0, 0)"
                  }}
                >
                  ⚠
                </div>
                <div
                  style={{
                    display: "inline-block",
                    color: "rgb(0, 0, 0)",
                    float: "none",
                    lineHeight: "32px",
                    margin: "0px 10px 0px 0px",
                    textAlign: "initial"
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      fontSize: "12px",
                      lineHeight: "18px"
                    }}
                  >
                    Starbase is currently fully occupied. Join the waitlist for
                    a chance to stay. <br />
                    But please note that{" "}
                    <b>we cannot guarantee availability.</b>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item md={2}>
              &nbsp;
            </Grid>

            <Grid item md={2}>
              &nbsp;
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          className="contactMobile car-personal-info sleep-customerinfoMobile"
          style={{ margin: "5px" }}
        >
          <Grid item xs={12} style={{ paddingTop: "15px" }}>
            <TextField
              error={errors.firstName && touched.firstName ? true : false}
              id="standard-name"
              name="firstName"
              value={values.firstName}
              onBlur={handleBlur}
              label={"First Name"}
              onChange={handleChange}
              helperText={
                errors.firstName && touched.firstName ? errors.firstName : ""
              }
              autoComplete="off"
              required={true}
            />
          </Grid>
          <Grid item xs={12} style={{}}>
            <TextField
              error={errors.lastName && touched.lastName ? true : false}
              id="standard-name"
              name="lastName"
              value={values.lastName}
              label={"Last Name"}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.lastName && touched.lastName ? errors.lastName : ""
              }
              autoComplete="off"
              required={true}
            />
          </Grid>
          <div className="mobileContainer">
            <div className="mobileNoDiv">
              <span
                className={`${
                  errors.mobileNo && touched.mobileNo ? "required" : ""
                }`}
              >
                Mobile Number (required for sms/notification)
                <span className="required">*</span>
              </span>
              <PhonePrefix
                mobileVal={values.mobileNo}
                handlePhonePrefix={value => setFieldValue("mobileNo", value)}
              />
              {errors.mobileNo && touched.mobileNo && (
                <span className="MuiFormHelperText-root Mui-error">
                  {errors.mobileNo}
                </span>
              )}
              <span className="fieldDescripiton">+1 (XXX) XXX-XXXX</span>
            </div>
          </div>
          <div className="emailContainer mobile-email-region">
            <div className="mobile-first-email">
              <TextField
                error={errors.email && touched.email ? true : false}
                id="standard-name"
                name="email"
                value={values.email}
                label={"Email"}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={errors.email && touched.email ? errors.email : ""}
                autoComplete="off"
                required={true}
                InputProps={{
                  readOnly: true
                }}
              />
              {!showCC && (
                <Button
                  size="small"
                  className="cc-email"
                  onClick={() => setShowCC(true)}
                >
                  CC
                </Button>
              )}
            </div>
          </div>
          {showCC && (
            <TextField
              className="report-purpose"
              id="second-email"
              name="cc_email"
              value={values.cc_email}
              onBlur={handleBlur}
              label={"CC"}
              onChange={handleChange}
              autoComplete="off"
              helperText="updates will also be send to this email address"
            />
          )}

          {isMultiple && (
            <TextField
              id="standard-name"
              name="cc_email"
              value={values.cc_email}
              label={"CC"}
              onBlur={handleBlur}
              onChange={handleChange}
              autoComplete="off"
              required={true}
            />
          )}
          {values.family_friend === 0 && (
            <>
              <span
                className={`${
                  errors.avgWork && touched.avgWork
                    ? "required sleep-avg-work-label"
                    : "sleep-avg-work-label"
                }`}
              >
                Do you work night shift or day shift?
                <span className="required">*</span>
              </span>
              <Select
                native
                value={values.avgWork}
                onChange={e => {
                  setFieldValue("avgWork", e.target.value);
                }}
                onBlur={handleBlur}
                IconComponent={ExpandMoreIcon}
                className={
                  errors.avgWork && touched.avgWork
                    ? "sleep-avg-work-mobile avg-error"
                    : "sleep-avg-work-mobile"
                }
              >
                <option value=""></option>
                <option value="day shift">day shift</option>
                <option value="night shift">night shift</option>
              </Select>
              {errors.avgWork && touched.avgWork && (
                <span
                  className={
                    errors.avgWork && touched.avgWork
                      ? "MuiFormHelperText-root Mui-error avg-error"
                      : "MuiFormHelperText-root Mui-error"
                  }
                >
                  {errors.avgWork}
                </span>
              )}

              {familyValue === SLEEP_FRIEND_BNB_OPTION && (
                <div className="housing_mobile">
                  <TextField
                    error={
                      errors.host_spacex_email && touched.host_spacex_email
                        ? true
                        : false
                    }
                    id="standard-name"
                    name="host_spacex_email"
                    value={values.host_spacex_email}
                    onBlur={handleBlur}
                    label={"Host spacex.com email"}
                    onChange={handleChange}
                    autoComplete="off"
                    helperText={
                      errors.host_spacex_email && touched.host_spacex_email
                        ? errors.host_spacex_email
                        : ""
                    }
                    required={true}
                  />
                </div>
              )}
              <Grid
                item
                xs={12}
                className={
                  errors.requests && touched.requests ? "searchErrMessage" : ""
                }
              >
                <TextField
                  name="requests"
                  className="notes_mobile"
                  value={values.requests}
                  onBlur={handleBlur}
                  label={"Reason for staying on site"}
                  onChange={handleChange}
                  placeholder="Reason for staying on site *"
                  required={true}
                  error={errors.requests && touched.requests ? true : false}
                  helperText={
                    errors.requests && touched.requests ? errors.requests : ""
                  }
                  InputProps={{
                    required: true,
                    className: "familyInput"
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: false
                  }}
                />
              </Grid>
              <Grid item sm={12} className="non-spacex-box">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={values.with_non_spacex}
                      checked={values.with_non_spacex}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox"
                      }}
                      onChange={(e, v) => setFieldValue("with_non_spacex", v)}
                    />
                  }
                  label="Travelling with non-SpaceX guests"
                ></FormControlLabel>
              </Grid>
            </>
          )}
          {/* <TextField
            error={errors.position && touched.position ? true : false}
            id="standard-name"
            name="position"
            value={values.position}
            onBlur={handleBlur}
            label={"What is your position?"}
            onChange={handleChange}
            autoComplete="off"
            helperText={
              errors.position && touched.position ? errors.position : ""
            }
            required={true}
          /> */}
        </Grid>

        {values.family_friend === 1 && (
          <>
            <h4 className="familyTitle mobile">Traveler to Starbase</h4>
            <Grid container className="familyFieldsMobile contactMobile">
              <Grid
                item
                xs={12}
                className={
                  errors.family_first_name && touched.family_first_name
                    ? "searchErrMessage"
                    : ""
                }
              >
                <TextField
                  error={
                    errors.family_first_name && touched.family_first_name
                      ? true
                      : false
                  }
                  name="family_first_name"
                  type="text"
                  label={"First Name"}
                  value={values.family_first_name}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Family or Friend first name*"
                  InputProps={{
                    required: true,
                    className: "familyInput familyName"
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: false
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={
                  errors.family_last_name && touched.family_last_name
                    ? "searchErrMessage"
                    : ""
                }
              >
                <TextField
                  error={
                    errors.family_last_name && touched.family_last_name
                      ? true
                      : false
                  }
                  name="family_last_name"
                  type="text"
                  label={"Last Name"}
                  value={values.family_last_name}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Family or Friend last name*"
                  InputProps={{
                    required: true,
                    className: "familyInput familyName"
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: false
                  }}
                />
              </Grid>
              {/* <Grid
                item
                xs={12}
                className={
                  errors.family_relationship && touched.family_relationship
                    ? "searchErrMessage"
                    : ""
                }
              >
                <TextField
                  error={
                    errors.family_relationship && touched.family_relationship
                      ? true
                      : false
                  }
                  name="family_relationship"
                  type="text"
                  label={"Relationship"}
                  value={values.family_relationship}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Relationship*"
                  InputProps={{
                    required: true,
                    className: "familyInput"
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: false
                  }}
                />
              </Grid> */}
              <div
                className={`${
                  errors.family_phone && touched.family_phone
                    ? "searchErrMessage"
                    : ""
                } mobileContainer`}
              >
                <div className="mobileNoDiv">
                  <span
                    className={`${
                      errors.family_phone && touched.family_phone
                        ? "required"
                        : ""
                    }`}
                  >
                    Mobile Number (required for sms/notification)
                    <span className="required">*</span>
                  </span>
                  <PhonePrefix
                    mobileVal={values.family_phone}
                    handlePhonePrefix={value =>
                      setFieldValue("family_phone", value)
                    }
                  />
                  {errors.family_phone && touched.family_phone && (
                    <span className="MuiFormHelperText-root Mui-error">
                      {errors.family_phone}
                    </span>
                  )}
                  <span className="fieldDescripiton">+1 (XXX) XXX-XXXX</span>
                </div>
              </div>
              <Grid
                item
                xs={12}
                className={
                  errors.family_email && touched.family_email
                    ? "searchErrMessage"
                    : ""
                }
              >
                <TextField
                  error={
                    errors.family_email && touched.family_email ? true : false
                  }
                  name="family_email"
                  type="text"
                  value={values.family_email}
                  autoComplete="off"
                  label={"Email"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Family or Friend email*"
                  InputProps={{
                    required: true,
                    className: "familyInput"
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: false
                  }}
                />
              </Grid>
              <div className="charter-region-mobile">
                <h4 className="charterTitleMobile">Required Information</h4>
                <span
                  className={`charterDobMobile ${
                    errors.dob && touched.dob ? "required" : ""
                  }`}
                >
                  Date of Birth <span className="required">*</span>
                </span>
                {/* <SingleDatePicker
                  id="dob"
                  date={values.dob === "" ? null : values.dob}
                  placeholder="MM/DD/YYYY"
                  focused={dateFocus}
                  onFocusChange={() => setDateFocus(true)}
                  onDateChange={date => setFieldValue("dob", moment(date))}
                  numberOfMonths={1}
                  navPrev={<div className="arrow-left" id="prevMonth"></div>}
                  navNext={<div className="arrow-right" id="nextMonth"></div>}
                  displayFormat="MM/DD/YYYY"
                  hideKeyboardShortcutsPanel={true}
                  keepOpenOnDateSelect={false}
                  onClose={() => setDateFocus(false)}
                  daySize={30}
                  transitionDuration={0}
                  isOutsideRange={() => false}
                  readOnly
                  renderMonthElement={RenderMonthElement}
                /> */}
                <Grid
                  item
                  xs={12}
                  className={`charter-info-cell-input ${
                    (errors.dobDay && touched.dobDay) ||
                    (errors.dobMonth && touched.dobMonth) ||
                    (errors.dobYear && touched.dobYear)
                      ? "searchErrMessage"
                      : ""
                  }`}
                >
                  {/* <SingleDatePicker
                          id="dob"
                          date={values.dob === "" ? null : values.dob}
                          placeholder="MM/DD/YYYY"
                          focused={dateFocus}
                          onFocusChange={() => setDateFocus(true)}
                          onDateChange={date =>
                            setFieldValue("dob", moment(date))
                          }
                          numberOfMonths={1}
                          navPrev={
                            <div className="arrow-left" id="prevMonth"></div>
                          }
                          navNext={
                            <div className="arrow-right" id="nextMonth"></div>
                          }
                          displayFormat="MM/DD/YYYY"
                          hideKeyboardShortcutsPanel={true}
                          keepOpenOnDateSelect={false}
                          onClose={() => setDateFocus(false)}
                          daySize={30}
                          transitionDuration={0}
                          isOutsideRange={() => false}
                          readOnly
                          renderMonthElement={RenderMonthElement}
                        /> */}
                  <Grid
                    container
                    style={{ paddingBottom: "25px", paddingTop: "5px" }}
                  >
                    <Grid item xs={5}>
                      <select
                        name="dobMonth"
                        className="selectBox"
                        required
                        value={values.dobMonth}
                        onChange={e => {
                          setFieldValue("dobMonth", e.target.value);
                          handleMonthChange(e.target.value);
                        }}
                      >
                        <MonthOptionComponent />
                      </select>
                    </Grid>
                    <Grid item xs={3}>
                      <select
                        name="dobDay"
                        className="selectBox"
                        value={values.dobDay}
                        onChange={e => setFieldValue("dobDay", e.target.value)}
                        required
                      >
                        <DayOptionComponent dayOption={maxDays} />
                      </select>
                    </Grid>
                    <Grid item xs={4}>
                      <select
                        className="selectBox"
                        name="dobYear"
                        value={values.dobYear}
                        onChange={e => {
                          setFieldValue("dobYear", e.target.value);
                          handleMonthChange(values.dobMonth);
                        }}
                        onBlur={handleBlur}
                      >
                        <YearOptionComponent />
                      </select>
                    </Grid>
                    {((errors.dobDay && touched.dobDay) ||
                      (errors.dobMonth && touched.dobMonth) ||
                      (errors.dobYear && touched.dobYear)) && (
                      <span className="errMessage">{errors.dobYear}</span>
                    )}
                  </Grid>
                </Grid>
              </div>
              {/* <div className="housing_mobile">
                <label>Housing preference</label>
                <select
                  className="selectBox housingSelect"
                  value={values.housing_preference}
                  onChange={handleChange}
                >
                  {roomArr && roomArr.length === 0 && (
                    <option value="waitlist">to be waitlisted</option>
                  )}
                  {roomArr && roomArr.length > 0 && (
                    <option value="no_preference">no preference</option>
                  )}
                  {roomArr &&
                    roomArr.length > 0 &&
                    roomArr.map((val, index) => {
                      return (
                        <option key={index} value={val.room_id}>
                          {val.alias}
                        </option>
                      );
                    })}
                </select>
              </div> */}
              <Grid
                item
                xs={12}
                className={
                  errors.requests && touched.requests ? "searchErrMessage" : ""
                }
              >
                <TextField
                  name="requests"
                  className="notes_mobile"
                  value={values.requests}
                  onBlur={handleBlur}
                  label={"Reason for staying on site"}
                  onChange={handleChange}
                  placeholder="Reason for staying on site *"
                  required={true}
                  error={errors.requests && touched.requests ? true : false}
                  helperText={
                    errors.requests && touched.requests ? errors.requests : ""
                  }
                  InputProps={{
                    required: true,
                    className: "familyInput"
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: false
                  }}
                />
              </Grid>
              <Grid item sm={12} className="non-spacex-box">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={values.with_non_spacex}
                      checked={values.with_non_spacex}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox"
                      }}
                      onChange={(e, v) => setFieldValue("with_non_spacex", v)}
                    />
                  }
                  label="Travelling with non-SpaceX guests"
                ></FormControlLabel>
              </Grid>
            </Grid>
          </>
        )}

        {/* <ConfirmationModal
          showConfirmation={showConfirmation}
          handleClose={handleOpenClose}
          isLoading={isLoading}
          handleContinue={handleSubmit}
        /> */}
        <Grid
          item
          xs={12}
          className="continueBox"
          style={{
            background: "transparent",
            border: "0px"
          }}
        >
          {!handleValidDate && (
            <span
              style={{
                color: "#fff",
                fontSize: "12px",
                display: "inline-block",
                width: "100%",
                textAlign: "right"
              }}
            >
              Please check your checkin and checkout date.
            </span>
          )}
          {errMsg !== "" && (
            <span
              style={{
                color: "#fff",
                fontSize: "12px",
                display: "inline-block",
                width: "100%",
                textAlign: "right"
              }}
            >
              {errMsg}
            </span>
          )}
          <Button
            className="btnContinue pull-right"
            id="fl-customer-btn-continue"
            onClick={handleSubmit}
            disabled={
              !handleValidDate ||
              (errMsg !== "" ? true : false) ||
              (isLoading ? true : false)
            }
          >
            Confirm Booking{" "}
            {!handleValidDate ||
              (errMsg !== "" ? <CircularProgress /> : "") ||
              (isLoading ? <CircularProgress /> : "")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SleepCustomerInfoMobile;
