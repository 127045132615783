import moment from "moment";

export const convertToMoment = date => (date ? moment(date, "DD-MM-YYYY") : "");

export const formatDisplayDate = date =>
  date ? moment(date, "DD-MM-YYYY").format("ddd, DD MMM YYYY") : "";

export const formatTime = date => (date ? moment(date).format("hh:mm A") : "");

export const isValidDate = date => {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!date.match(regEx)) return false; // Invalid format
  var d = new Date(date);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === date;
};
