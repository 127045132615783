import React from "react";
import { Redirect } from "react-router-dom";
import { AUTH_TOKEN } from "./constants";

const Logout = () => {
  localStorage.removeItem(AUTH_TOKEN);
  return <Redirect to="/login" />;
};

export default Logout;
