import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Grid from "@material-ui/core/Grid";

const styles = { marginTop: 30 };

const LoadingComponent = () => (
  <React.Fragment>
    <div
      style={{
        position: "fixed",
        display: "block",
        padding: "0",
        marginTop: "75px",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "#273142"
      }}
    >
      <SkeletonTheme color="#f1f1f1" highlightColor="#f9f9f9">
        <Grid container className="loadingWrapper">
          <Grid item sm={4} xs={7} className="infoSection">
            <span>
              <Skeleton width={"80%"} height={12} duration={2} />
            </span>
            <br />
            <span>
              <Skeleton width={"60%"} height={12} />
            </span>
            <br />
          </Grid>
          <Grid item sm={3} xs={5}>
            <span>
              <Skeleton width={"80%"} height={12} />
            </span>
            <br />
            <span className="airlnelbl">
              <Skeleton width={"60%"} height={12} />
            </span>
          </Grid>
          <Grid item sm={3} xs={6} className="duration">
            <span>
              <Skeleton width={150} height={12} />
            </span>
            <br />
            <span className="airlnelbl">
              <Skeleton width={100} height={12} />
            </span>
          </Grid>
          <Grid item sm={2} xs={6}>
            <span>
              <Skeleton width={120} height={12} />
            </span>
            <br />
            <span className="airlnelbl">
              <Skeleton width={100} height={12} />
            </span>
          </Grid>
          <Grid item sm={3} xs={6} style={styles}>
            <span>
              <Skeleton width={100} height={12} />
            </span>
          </Grid>
          <Grid item sm={3} xs={6} style={styles}>
            <span>
              <Skeleton width={100} height={12} />
            </span>
          </Grid>
        </Grid>
        <Grid container className="loadingWrapper">
          <Grid item sm={4} xs={7} className="infoSection">
            <span>
              <Skeleton width={"80%"} height={12} duration={2} />
            </span>
            <br />
            <span>
              <Skeleton width={"60%"} height={12} />
            </span>
            <br />
          </Grid>
          <Grid item sm={3} xs={5}>
            <span>
              <Skeleton width={"80%"} height={12} />
            </span>
            <br />
            <span className="airlnelbl">
              <Skeleton width={"60%"} height={12} />
            </span>
          </Grid>
          <Grid item sm={3} xs={6} className="duration">
            <span>
              <Skeleton width={150} height={12} />
            </span>
            <br />
            <span className="airlnelbl">
              <Skeleton width={100} height={12} />
            </span>
          </Grid>
          <Grid item sm={2} xs={6}>
            <span>
              <Skeleton width={120} height={12} />
            </span>
            <br />
            <span className="airlnelbl">
              <Skeleton width={100} height={12} />
            </span>
          </Grid>
          <Grid item sm={3} xs={6} style={styles}>
            <span>
              <Skeleton width={100} height={12} />
            </span>
          </Grid>
          <Grid item sm={3} xs={6} style={styles}>
            <span>
              <Skeleton width={100} height={12} />
            </span>
          </Grid>
        </Grid>
      </SkeletonTheme>
    </div>
  </React.Fragment>
);

export default LoadingComponent;
