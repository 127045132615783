import React, { useEffect, useState } from "react";
import "assets/scss/SleepDoorCode.scss";
import Axios from "axios";
import { Button, CircularProgress } from "@material-ui/core";

const SleepCheckout = ({ history, match }) => {
  const [room, setRoom] = useState(null);
  const [flag, setFlag] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const CHECK_RESERVATION = "/public/reservation/info";

  useEffect(() => {
    if (!flag) {
      Axios.post(CHECK_RESERVATION, {
        bookingCode: window.location.pathname.split("/")[3]
      })
        .then(res => res.data && setRoom(res.data.data))
        .catch(err => {
          setRoom(null);
          setStatus("not found");
        });
      setFlag(true);
    }
  }, [flag]);

  const checkoutSleepBooking = () => {
    Axios.post("/public/reservation/checkoutSleepBooking", {
      bookingCode: window.location.pathname.split("/")[3]
    })
      .then(res => res.data && history.push("/sleep/checkout-confirmation"))
      .catch(err => history.push("/sleep/checkout-confirmation"));
  };

  return (
    <div className="SleepDoorCode">
      <div className="wrapper">
        {status === "not found" ? (
          <h2 className="title">Reservation Not Found</h2>
        ) : (
          <>
            <h2 className="title" style={{ textAlign: "center" }}>
              Your Reservation Details
            </h2>
            <p className="booking-id">
              You are staying in <span>{room && room.room_type}</span>
              <br /> in room number <span>{room && room.room_number}</span>
            </p>
            <br />
            {!showCheckout && (
              <div style={{ textAlign: "center" }}>
                <h2
                  className="title"
                  style={{ textAlign: "center", marginBottom: "0px" }}
                >
                  Kindly press the button below to check-out
                </h2>
                <Button
                  className="btnContinue btnCheckoutRes"
                  onClick={() => setShowCheckout(true)}
                >
                  check-out reservation
                </Button>
              </div>
            )}
            {showCheckout && (
              <div style={{ textAlign: "center" }}>
                <h2
                  className="title"
                  style={{ textAlign: "center", marginBottom: "0px" }}
                >
                  Are you sure you want to proceed with the check-out?
                </h2>
                <Button
                  className="btnContinue btnCheckoutYes"
                  onClick={() => {
                    setLoading(true);
                    checkoutSleepBooking();
                  }}
                  disabled={loading}
                >
                  Yes {loading && <CircularProgress />}
                </Button>
                <Button
                  className="btnContinue btnCheckoutNo"
                  onClick={() => setShowCheckout(false)}
                >
                  No
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SleepCheckout;
