import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";
import Loadable from "react-loadable";
import SpacexWrapper from "./SpacexWrapper";

const SleepSearch = Loadable({
  loader: () => import("pages/spacex/SleepSearch"),
  loading() {
    return <div></div>;
  }
});

const SleepPage = props => {
  const [pageContent] = useState({});
  const [showRecentSearches] = useState(true);

  return (
    <SpacexWrapper>
      <div>
        <Helmet>
          <title>SpaceX</title>
        </Helmet>

        <Container className={"spacex-sleep-bgHome "} id="BackgroundContainer">
          <Grid container className={"homeContainer"}>
            <SleepSearch
              pageContent={pageContent}
              showRecentSearches={showRecentSearches}
            />
          </Grid>
        </Container>
      </div>
    </SpacexWrapper>
  );
};

export default SleepPage;
