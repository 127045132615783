import React, { useState } from "react";
import moment from "moment";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import CheckListIcon from "../../../../assets/images/checklist-confirmation.svg";

const SleepModifyConfirmation = props => {
  const [personInfo] = useState(
    props.location.state && props.location.state.personInfo
      ? props.location.state.personInfo
      : null
  );

  return (
    <>
      <SpacexNavbar />
      <div style={{ minHeight: "100vh", backgroundColor: "#273142" }}>
        <div className="confirmation-upper">
          <img src={CheckListIcon} alt="Confirmation" />
          <span className="title" style={{ color: "#2cafe8" }}>
            Your reservation modification has been received.
          </span>
          <span className="title" style={{ color: "#2cafe8" }}>
            Please note that this is not final and admin will need to
            approve/reject.
          </span>
          <div
            className="MuiGrid-root confirmation-lower MuiGrid-container"
            style={{ maxWidth: "300px" }}
          >
            <div className="MuiGrid-root MuiGrid-item">
              <div className="bookingdetail">
                <div className="bookingdate-half">
                  <div className="bookingdiv">
                    <span className="bookingheader">Date Check-in</span>
                    <span className="bookingtext">
                      {personInfo.old_checkin_date
                        ? moment(
                            personInfo.old_checkin_date,
                            "YYYY-MM-DD"
                          ).format("ddd, DD MMM YYYY")
                        : ""}
                    </span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Date Check-in</span>
                    <span className="bookingtext">
                      {personInfo.checkin_date
                        ? moment(personInfo.checkin_date, "YYYY-MM-DD").format(
                            "ddd, DD MMM YYYY"
                          )
                        : ""}
                    </span>
                  </div>
                </div>

                <div className="bookingdate-half">
                  <div className="bookingdiv">
                    <span className="bookingheader">Date Check-out</span>
                    <span className="bookingtext">
                      {personInfo.old_checkout_date
                        ? moment(
                            personInfo.old_checkout_date,
                            "YYYY-MM-DD"
                          ).format("ddd, DD MMM YYYY")
                        : ""}
                    </span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Date Check-out</span>
                    <span className="bookingtext">
                      {personInfo.checkout_date
                        ? moment(personInfo.checkout_date, "YYYY-MM-DD").format(
                            "ddd, DD MMM YYYY"
                          )
                        : ""}
                    </span>
                  </div>
                </div>
                <br />
                <div className="bookingdiv">
                  <span className="bookingheader">First Name</span>
                  <span className="bookingtext">{personInfo.first_name}</span>
                </div>
                <div className="bookingdiv">
                  <span className="bookingheader">Last Name</span>
                  <span className="bookingtext">{personInfo.last_name}</span>
                </div>
                <div className="bookingdiv">
                  <span className="bookingheader">Email</span>
                  <span className="bookingtext">{personInfo.email}</span>
                </div>
                <div className="bookingdiv">
                  <span className="bookingheader">Phone</span>
                  <span className="bookingtext">{personInfo.phone}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SleepModifyConfirmation;
