import React, { useState } from "react";
import moment from "moment";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import CheckListIcon from "../../../assets/images/checklist-confirmation.svg";

const SleepConfirmation = props => {
  const [personInfo] = useState(
    props.location.state && props.location.state.personInfo
      ? props.location.state.personInfo
      : null
  );

  return (
    <>
      <SpacexNavbar />
      <div style={{ minHeight: "100vh", backgroundColor: "#273142" }}>
        {personInfo ? (
          <div className="confirmation-upper">
            <img src={CheckListIcon} alt="Confirmation" />
            <span className="title" style={{ color: "#2cafe8" }}>
              {props.location.state.roomArr.length === 0
                ? "Your reservation has been waitlisted."
                : "Your reservation has been received."}
            </span>
            {props.location.state.roomArr.length === 0 && (
              <div
                style={{
                  background: "#ffcc00",
                  textAlign: "center",
                  padding: "6px 0px 6px 0px",
                  marginTop: "15px",
                  borderRadius: "4px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "360px",
                  marginBottom: "20px"
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    fontSize: "32px",
                    lineHeight: "29px",
                    width: "32px",
                    height: "32px",
                    padding: "0px 6px",
                    color: "#800000",
                    float: "left"
                  }}
                >
                  ⚠
                </div>
                <div
                  style={{
                    display: "inline-block",
                    color: "#000000",
                    float: "none",
                    lineHeight: "32px",
                    margin: "0px 10px 0px 0px"
                  }}
                >
                  <div style={{ display: "block", fontSize: "12px" }}>
                    <b>Please note that we cannot guarantee availability.</b>
                  </div>
                </div>
              </div>
            )}
            <div
              className="MuiGrid-root confirmation-lower MuiGrid-container"
              style={{ maxWidth: "300px" }}
            >
              <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12">
                <div className="bookingdetail">
                  {/* <div className="bookingdiv">
                  <span className="bookingheader">Room Number</span>
                  <span className="bookingtext">{`${roomInfo.name} ${
                    roomInfo.room_id ? roomInfo.room_id : ""
                  }`}</span>
                </div> */}
                  <div className="bookingdiv">
                    <span className="bookingheader">Date Check-in</span>
                    <span className="bookingtext">
                      {personInfo.checkin_date
                        ? moment(personInfo.checkin_date, "YYYY-MM-DD").format(
                            "ddd, DD MMM YYYY"
                          )
                        : ""}
                    </span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Date Check-out</span>
                    <span className="bookingtext">
                      {personInfo.checkout_date
                        ? moment(personInfo.checkout_date, "YYYY-MM-DD").format(
                            "ddd, DD MMM YYYY"
                          )
                        : ""}
                    </span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">First Name</span>
                    <span className="bookingtext">{personInfo.first_name}</span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Last Name</span>
                    <span className="bookingtext">{personInfo.last_name}</span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Email</span>
                    <span className="bookingtext">{personInfo.email}</span>
                  </div>
                  <div className="bookingdiv">
                    <span className="bookingheader">Phone</span>
                    <span className="bookingtext">{personInfo.phone}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SleepConfirmation;
