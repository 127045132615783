import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Button,
  CircularProgress,
  Checkbox
} from "@material-ui/core";
import TermsAndConditonsModal from "../cars/TermsAndConditions";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";

const styles = { marginTop: 30 };

const CarCustomerInfoWeb = props => {
  let {
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
    handleBlur,
    handleSubmit,
    handleValidDate,
    errMsg,
    isLoading
  } = props;
  const [retDateVal, setRetDateVal] = useState(moment());
  const [dateFocus, setDateFocus] = useState(false);
  const [checkedTerm, setChecked] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  useEffect(() => {
    setRetDateVal(
      props.location.state && moment(props.location.state.startDate)
    );
    setFieldValue(
      "startDate",
      props.location.state && props.location.state.startDate
    );
    setFieldValue("startTime", moment().format("HH:mm"));
    setFieldValue("name", props.location.state && props.location.state.name);
  }, [props.location.state, setFieldValue]);

  useEffect(() => {
    setFieldValue("est_return_date", retDateVal.format("YYYY-MM-DD"));
  }, [retDateVal, setFieldValue]);

  function onChange(value) {
    setFieldValue("startTime", moment(value).format("HH:mm"));
  }
  console.log(errors, touched);
  return (
    <React.Fragment>
      <form
        style={{
          // position: "fixed", // prevent more space at bottom (can delete this after add more fields)
          // overflow: "auto", // just open this after add more fields
          padding: "0",
          margin: "0",
          top: "80px", // after open overflow: "auto", set to "0px" again
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "#273142"
        }}
      >
        <Grid container className="customerContainer car-personal-info">
          <Grid item md={2}>
            &nbsp;
          </Grid>
          <Grid item xs={8} sm={8}>
            <span className="title" style={{ color: "#ffffff" }}>
              Personal Information
            </span>
          </Grid>
          <Grid item md={2}>
            &nbsp;
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>
          <Grid item md={8}>
            <Grid container className="contactInfo">
              <Grid
                item
                md={6}
                className={
                  errors.employee_name && touched.employee_name
                    ? "searchErrMessage "
                    : ""
                }
              >
                <span className="fieldTitle">Employee Name</span>
                <span className="required">*</span>
                <TextField
                  name="employee_name"
                  value={values.employee_name || ""}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                />
                {errors.employee_name && touched.employee_name && (
                  <span className="errMessage">{errors.employee_name}</span>
                )}
              </Grid>
              <Grid item sm={6}>
                <span className="fieldTitle">Item</span>
                <TextField
                  name="unit_id"
                  value={
                    (props.location.state && props.location.state.name) || ""
                  }
                  autoComplete="off"
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  disabled
                />
              </Grid>

              {/* <Grid item sm={12} className="p-bottom-15"></Grid> */}

              <Grid
                item
                sm={6}
                className={
                  errors.start_date && touched.start_date
                    ? "searchErrMessage"
                    : ""
                }
              >
                <span className="fieldTitle">Start Time</span>
                <span className="required">*</span>
                <Grid container className="date-region">
                  <TextField
                    error={
                      errors.start_date && touched.start_date ? true : false
                    }
                    id="standard-name"
                    name="start_date"
                    value={values.startDate || ""}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={
                      errors.start_date && touched.start_date
                        ? errors.start_date
                        : ""
                    }
                    autoComplete="off"
                    required={true}
                    style={{ width: "75%" }}
                    disabled
                  />
                  <TimePicker
                    style={{ width: 100 }}
                    defaultValue={moment()}
                    onChange={onChange}
                    showSecond={false}
                  />
                </Grid>
                {errors.start_time && touched.start_time && (
                  <span className="errMessage">{errors.start_time}</span>
                )}
              </Grid>

              <Grid item sm={6} style={{ paddingLeft: "15px" }}>
                <Grid container className="date-region">
                  <div className="date-inner car-date">
                    <span className="fieldTitle">Est Return Date</span>
                    <span className="required">*</span>
                    <SingleDatePicker
                      id="carStartdate"
                      date={retDateVal}
                      placeholder="Est return date"
                      focused={dateFocus}
                      onFocusChange={() => setDateFocus(true)}
                      onDateChange={date => {
                        console.log(date);
                        setRetDateVal(date);
                      }}
                      numberOfMonths={1}
                      navPrev={
                        <div className="arrow-left" id="prevMonth"></div>
                      }
                      navNext={
                        <div className="arrow-right" id="nextMonth"></div>
                      }
                      displayFormat="YYYY-MM-DD"
                      hideKeyboardShortcutsPanel={true}
                      keepOpenOnDateSelect={false}
                      onClose={() => setDateFocus(false)}
                      showDefaultInputIcon
                      inputIconPosition="after"
                      daySize={30}
                      transitionDuration={0}
                      isOutsideRange={date => date.isBefore(values.startDate)}
                      readOnly
                    />
                  </div>
                </Grid>
              </Grid>

              {/* <Grid
                item
                sm={6}
                style={{ paddingLeft: "15px" }}
                className={
                  errors.start_date && touched.start_date
                    ? "searchErrMessage "
                    : ""
                }
              >
                ???
              </Grid> */}
              {/* <Grid item sm={12} className="p-bottom-15"></Grid> */}

              <Grid
                item
                sm={6}
                className={
                  errors.email && touched.email ? "searchErrMessage " : ""
                }
              >
                <span className="fieldTitle">Email</span>
                <span className="required">*</span>
                <TextField
                  name="email"
                  type="text"
                  value={values.email || ""}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <span className="errMessage">{errors.email}</span>
                )}
              </Grid>
              <Grid
                item
                sm={6}
                style={{ paddingLeft: "15px !important" }}
                className={
                  errors.phone && touched.phone ? "searchErrMessage " : ""
                }
              >
                <span className="fieldTitle">Phone</span>
                <span className="required">*</span>
                <TextField
                  name="phone"
                  type="text"
                  value={values.phone || ""}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.phone && touched.phone && (
                  <span className="errMessage">{errors.phone}</span>
                )}
              </Grid>
              {/* <Grid item sm={12} className="p-bottom-15"></Grid> */}

              <Grid
                item
                sm={6}
                className={
                  errors.pickup_location && touched.pickup_location
                    ? "searchErrMessage "
                    : ""
                }
              >
                <span className="fieldTitle">Pickup Location</span>
                <span className="required">*</span>
                <TextField
                  name="pickup_location"
                  type="text"
                  value={values.pickup_location || ""}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={true}
                />
                {errors.pickup_location && touched.pickup_location && (
                  <span className="errMessage">{errors.pickup_location}</span>
                )}
              </Grid>
              <Grid
                item
                sm={6}
                className={
                  errors.dropoff_location && touched.dropoff_location
                    ? "searchErrMessage "
                    : ""
                }
              >
                <span className="fieldTitle">Drop-off Location</span>
                <span className="required">*</span>
                <TextField
                  name="dropoff_location"
                  type="text"
                  value={values.dropoff_location || ""}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.dropoff_location && touched.dropoff_location && (
                  <span className="errMessage">{errors.dropoff_location}</span>
                )}
              </Grid>

              {/* <Grid item sm={12} className="p-bottom-15"></Grid> */}

              <Grid item md={6}>
                <span className="fieldTitle">Note</span>
                <TextField
                  name="notes"
                  value={values.notes || ""}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item sm={6} className="car-reason">
                <span className="fieldTitle">Reason for Reservation</span>
                <TextField
                  name="reason"
                  value={values.reason || ""}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>

            <Grid item sm={12} style={styles}>
              <TermsAndConditonsModal
                showConfirmation={showTerms}
                handleClose={() => setShowTerms(false)}
                isLoading={props.isLoading}
                handleContinue={handleSubmit}
              />

              <div
                style={{
                  color: "#fff",
                  fontSize: "14px",
                  marginTop: "15px",
                  display: "block",
                  marginRight: "10px"
                }}
              >
                <div
                  className="MuiGrid-root contactInfo MuiGrid-container"
                  style={{ background: "#ffcc00" }}
                >
                  <div
                    style={{
                      display: "block",
                      float: "left",
                      fontSize: "40px",
                      lineHeight: "40px",
                      width: "44px",
                      height: "44px",
                      padding: "0px 6px",
                      color: "#800000"
                    }}
                  >
                    ⚠
                  </div>
                  <div
                    style={{
                      display: "block",
                      color: "#000000"
                    }}
                  >
                    <div
                      style={{
                        display: "block"
                      }}
                    >
                      Please email your insurance cards to&nbsp;
                      <b>starbaseteslareservations@spacex.com</b>
                    </div>
                    <div
                      style={{
                        display: "block"
                      }}
                    >
                      In order the travel manager to accept your car request.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "block",
                    textAlign: "center"
                  }}
                >
                  <Checkbox
                    value={checkedTerm}
                    checked={checkedTerm}
                    color="primary"
                    inputProps={{
                      "aria-label": "secondary checkbox"
                    }}
                    onChange={(e, v) => {
                      setChecked(v);
                    }}
                  />
                  By clicking "Confirm Booking" you are agreeing to the{" "}
                  <span
                    onClick={() => setShowTerms(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <u>Terms and Conditons</u>
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  id="fl-customer-btn-continue"
                  className="btnContinue pull-right"
                  onClick={handleSubmit}
                  disabled={!checkedTerm}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    float: "none !important"
                  }}
                >
                  Confirm Booking{" "}
                  {!handleValidDate ||
                    (errMsg !== "" ? <CircularProgress /> : "") ||
                    (isLoading ? <CircularProgress /> : "")}
                </Button>
              </div>
            </Grid>
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default CarCustomerInfoWeb;
