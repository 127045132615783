import React, { useEffect, useState } from "react";
import axios from "axios";
import HovercraftCustomerDetailForm from "pages/spacex/hovercraft/HovercraftCustomerDetailForm";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import SignaturePage from "../SignaturePage";
import _ from "lodash";
import moment from "moment";

const HovercraftPersonalInfo = props => {
  const [pageContent] = useState({});
  const [utm_medium] = useState(null);
  const [utm_campaign] = useState(null);
  const [hovercraftId] = useState(
    props.match.params.hovercraftId ? props.match.params.hovercraftId : null
  );
  const [isBrownsville] = useState(
    props.match.params.isBrownsville && props.match.params.isBrownsville === "1"
  );
  const [showNda, setShowNda] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [additionalBaggage, setAdditionalBaggage] = useState(false);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (!flag) {
      let params = {
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment()
          .add(30, "days")
          .format("YYYY-MM-DD")
      };

      axios
        .post("/api/hovercraft/availability", params)
        .then(function(response) {
          response.data && response.data.error && response.data.hovercrafts([]);
        })
        .catch(function(error) {
          console.log(error);
        });
      setFlag(true);
    }
  }, [flag]);

  const handleSetSignature = signature => {
    handleReservation({ ...bookingData, signature }, false);
  };

  const handleAdditionalBaggage = () =>
    setAdditionalBaggage(!additionalBaggage);

  const shouldShowNda = () =>
    props.location.state &&
    props.location.state.isNda === "1" &&
    showNda === false;

  const handleReservation = (data, storeParams = true) => {
    let params = {
      route_id: hovercraftId,
      name: data.customer_name,
      phone: data.customer_phone,
      email: data.customer_email,
      cc_email: data.cc_email,
      manager_email: data.manager_email
    };

    // if (isCharter) {
    //   params["charter"] = isCharter;
    // }

    if (
      data.manager_email !== sessionStorage.getItem("manager_email") &&
      data.manager_email !== ""
    ) {
      sessionStorage.setItem("manager_email", data.manager_email);
    }

    if (storeParams) {
      setBookingData(data);

      if (shouldShowNda()) {
        setShowNda(true);
        return;
      }
    }

    axios
      .post("/api/hovercraft/book", params)
      .then(response => {
        if (response.data && response.data.error) {
          alert(response.data.message);
          return;
        }

        response.data &&
          !response.data.error &&
          response.data.hovercrafts &&
          props.history.push({
            pathname: "/hovercraft/confirmation",
            search: window.location.search,
            state: { chosenHovercraft: response.data.hovercrafts }
          });

        let err = [];
        _.forOwn(response.data.errors, (value, key) => {
          err.push(value);
        });
        response.data && response.data.error && setErrMsg(err.join(","));
      })
      .catch(error =>
        setErrMsg("We are sorry, your booking is not successful")
      );
  };

  if (showNda)
    return (
      <SignaturePage
        handleReservation={handleReservation}
        setSignature={handleSetSignature}
        errMsg={errMsg}
      />
    );

  return (
    <React.Fragment>
      <SpacexNavbar />
      <HovercraftCustomerDetailForm
        handleReservation={handleReservation}
        pageType="customerDetails"
        pageContent={pageContent}
        utmMedium={utm_medium}
        utmCampaign={utm_campaign}
        isBrownsville={isBrownsville}
        additionalBaggage={additionalBaggage}
        handleAdditionalBaggage={handleAdditionalBaggage}
        errMsg={errMsg}
        submitText={
          props.location.state && props.location.state.isNda === "1"
            ? "Continue"
            : "Confirm Seat!"
        }
      />
    </React.Fragment>
  );
};

export default HovercraftPersonalInfo;
