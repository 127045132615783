import React from "react";

const DayOptionComponent = ({ pageContent, dayOption }) => (
  <>
    <option value="00" disabled>
      {pageContent && pageContent.day
        ? pageContent.day
        : pageContent && pageContent.pageContent && pageContent.pageContent.day
        ? pageContent && pageContent.pageContent.day
        : "Day"}
    </option>
    {Array.apply(0, Array(dayOption)).map(function(x, i) {
      return (
        <option
          key={i}
          value={(i + 1).toString().length === 1 ? "0" + (i + 1) : i + 1}
        >
          {(i + 1).toString().length === 1 ? "0" : ""}
          {i + 1}
        </option>
      );
    })}
  </>
);

export default DayOptionComponent;
