import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Container } from "@material-ui/core";
import SpacexWrapper from "./SpacexWrapper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link", "image"]
  ]
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image"
];

const Information = () => {
  const [editorState, setEditorState] = useState("");
  const [docVal, setDocVal] = useState([]);
  const [titles, setTitles] = useState([]);
  const [active, setActive] = useState(null);

  useEffect(() => {
    setDocVal(document.getElementsByTagName("h2"));
  }, [editorState]);

  return (
    <SpacexWrapper>
      <Helmet>
        <title>SpaceX</title>
      </Helmet>
      <Container className="spacex-important-info" id="BackgroundContainer">
        <Grid container className={"homeContainer"}>
          {/* <div
            className="contentEdit"
            contentEditable={true}
            suppressContentEditableWarning
            onChange={e => {
              console.log(e.currentTarget.textContent);
            }}
          >
            user can start typing text in here
          </div> */}
          <Grid item md={3} xs={12} sm={12}>
            <nav className="nav-title">
              <ul>
                {titles.length > 0 &&
                  titles.map((title, key) => {
                    return (
                      <li
                        key={key}
                        className={active === key ? "active" : ""}
                        onClick={() => {
                          setActive(key);
                          let hArr = document.getElementsByTagName("h2");
                          if (hArr && hArr.length > 0) {
                            for (let i = 0; i < hArr.length; i++) {
                              let check = hArr[i].innerHTML
                                ? hArr[i].innerHTML
                                : "";
                              check === title && hArr[i].scrollIntoView();
                            }
                          }
                        }}
                      >
                        {title}
                      </li>
                    );
                  })}
              </ul>
            </nav>
          </Grid>
          <Grid item md={9} xs={12} sm={12}>
            <ReactQuill
              value={editorState}
              onChange={val => {
                setEditorState(val);
                //let gh = document.getElementsByTagName("h2");
                if (docVal && docVal.length > 0) {
                  let arr = [];
                  for (let i = 0; i < docVal.length; i++) {
                    arr.push(docVal[i].innerHTML);
                  }
                  setTitles(arr);
                }
              }}
              modules={modules}
              formats={formats}
            />
          </Grid>
        </Grid>
      </Container>
    </SpacexWrapper>
  );
};

export default Information;
