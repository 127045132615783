import React from "react";
import { Grid, Modal, Card } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const TermsAndConditions = ({ showConfirmation, handleClose }) => {
  return (
    <React.Fragment>
      <Modal open={showConfirmation} onClose={handleClose}>
        <Card className="confirmationBox termsBox">
          <Grid item={true} sm={12}>
            {" "}
            <CloseIcon
              onClick={handleClose}
              style={{
                float: "right",
                cursor: "pointer"
              }}
            />
          </Grid>
          <Grid item={true} sm={12} className="car-terms">
            <div className="MuiGrid-root car-terms MuiGrid-item MuiGrid-grid-sm-12">
              <u
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  textAlign: "center"
                }}
              >
                Waiver of Liability, Release, Indemnity and Hold Harmless
                Agreement{" "}
              </u>
              <br />
              <h3
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  textAlign: "justify",
                  textAlignLast: "center"
                }}
              >
                ***YOU MUST READ, ACCEPT AND SIGN THIS AGREEMENT BEFORE DRIVING
                OR RIDING IN ANY SPACEX TESLA. BY SIGNING THIS AGREEMENT, YOU
                AGREE TO RELEASE ENTITIES AND INDIVIDUALS FROM ANY LIABILITY
                THAT MAY ARISE FROM YOUR USE OF A SPACEX TESLA***
              </h3>

              <p>
                In consideration for and as a condition of my decision to drive
                or ride in a Space Exploration Technologies Corp. (“SpaceX”)
                Tesla, I agree as follows:{" "}
              </p>

              <p>
                This Waiver of Liability, Release, Indemnity, and Hold Harmless
                Agreement (“Agreement”) fully and without reservation releases
                SpaceX and each of its related entities and all of their
                respective directors, officers, executives, agents, contractors,
                and employees (collectively, including SpaceX, the “Releasees”)
                from any and all liability relating in any way to my driving or
                riding in any SpaceX Tesla.
              </p>

              <p>
                <b>
                  <u>Insurance for Driving a Tesla.</u>
                </b>{" "}
                I agree that I will not drive a SpaceX Tesla unless I have a
                valid driver’s license and personal automobile insurance that
                meets the legal requirements of the jurisdiction where I would
                drive the SpaceX Tesla, and that if I drive a SpaceX Tesla my
                automobile insurance policy will be the primary policy that will
                cover any property damage, injury, harm, third party claim, or
                other liability I or others may suffer or cause in connection
                with my driving a SpaceX Tesla. In the event any accident occurs
                while I am driving a SpaceX Tesla, I shall report the accident
                to my personal insurance carrier within 24 hours of the
                accident. I understand and agree that any automobile insurance
                held by any of the Releasees may protect only the Releasees’
                interests and will not serve as or be a substitute for the
                liability insurance that I am required by law to carry and
                represent that I have.{" "}
              </p>

              <p>
                <b>
                  <u>Voluntary Use.</u>
                </b>{" "}
                I acknowledge that I have no obligation to drive or ride in a
                SpaceX Tesla and that driving or riding in a SpaceX Tesla is not
                a job duty or assignment that I have for SpaceX. SpaceX has not
                directed me to drive or ride in a SpaceX Tesla or instructed me
                to drive or ride to a particular destination at a particular
                time or on a particular route. I have decided voluntarily to do
                so.
              </p>

              <p>
                <b>
                  <u>Acknowledgements.</u>
                </b>{" "}
                I acknowledge that I am experienced in and familiar with the
                operation of Tesla vehicles and expressly agree to exercise care
                and all safety precautions before and when driving any SpaceX
                Tesla. I acknowledge that I have read, understand, and will
                comply with the Tesla Rideshare Policies & Procedures (attached
                as Exhibit A) and further represent that I will comply with all
                safety and traffic laws where I am driving or riding in any
                SpaceX Tesla. I acknowledge that I shall not drive or ride in
                any SpaceX Tesla if I believe for any reason that I cannot do so
                safely.
              </p>

              <p>
                <b>
                  <u>RELEASE AND WAIVER.</u> I, ON BEHALF OF MYSELF, SPOUSE,
                  HEIRS, AGENTS, ASSIGNS, AND REPRESENTATIVES, AGREE TO FULLY
                  AND IRREVOCABLY WAIVE AND RELEASE ALL RELEASEES FROM ANY AND
                  ALL CLAIMS OR CAUSES OF ACTION WHICH I MAY NOW HAVE OR MAY
                  HAVE IN THE FUTURE AGAINST ANY OF THE RELEASEES FOR ANY
                  PERSONAL INJURY, INCLUDING WIHTOUT LIMITATION DEATH, OR ANY
                  LOSS, HARM OR DAMAGE TO ME OR MY PERSONAL PROPERTY, OR OTHER
                  DAMAGES, LOSSES, COSTS, AND/OR EXPENSES, INCLUDING REASONABLE
                  ATTORNEYS’ FEES, ARISING OUT OF OR RELATED IN ANY WAY TO ANY
                  AND/OR ALL OPERATION, DESIGN, MANUFACTURE, PREPARATION, REPAIR
                  OR MAINTENANCE OF ANY OF THE SPACEX TESLAS THAT I MAY DRIVE OR
                  RIDE IN. THIS COMPREHENSIVE WAIVER AND RELEASE EXTENDS TO ANY
                  AND ALL CLAIMS OR CAUSES OF ACTION I HAVE OR MAY HAVE AGAINST
                  ANY OF THE RELEASEES RELATED TO ANY OF THE FOREGOING, EVEN IF
                  SUCH CLAIMS OR CAUSES OF ACTION RESULT FROM OR ARE ALLEGED TO
                  RESULT{" "}
                </b>
              </p>

              <p>
                <b>
                  <u>INDEMNITY.</u> I FURTHER AGREE TO RELEASE, PROTECT, DEFEND,
                  INDEMNIFY AND HOLD HARMLESS ALL RELEASEES FROM AND AGAINST ANY
                  AND ALL CLAIMS, DAMAGES, LOSSES, HARM, COSTS, AND EXPENSES,
                  INCLUDING REASONABLE ATTORNEYS’ FEES, ARISING OUT OF OR
                  RELATED IN ANY WAY TO: (1) MY DRIVING OR RIDING IN ANY SPACEX
                  TESLA; (2) ANY AND ALL INTERACTION WITH, OPERATION, DESIGN,
                  MANUFACTURE, PREPARATION, REPAIR OR MAINTENANCE OF ANY SPACEX
                  TESLA THAT I DRIVE OR RIDE IN; (3) ANY CONDITIONS AND/OR
                  CIRCUMSTANCES UNDER WHICH MY DRIVING OR RIDING IN A SPACEX
                  TESLA IS PERMITTED ORCONDUCTED; AND/OR (4) ANY OTHER CAUSE,
                  DAMAGE, INJURY OR HARM WHICH ANY THIRD PARTY MAY CLAIM TO HAVE
                  SUFFERED, PROPERTY DAMAGE OR PERSONAL INJURIES, BODILY
                  INCLUDING INJURY OR DEATH RELATING IN ANY WAY TO MY DRIVING OR
                  RIDING IN ANY SPACEX TESLA, EVEN IF CAUSED IN WHOLE OR IN PART
                  BY ANY OF THE RELEASEES OR THE SPACEX TESLA WHETHER DUE TO
                  THEIR NEGLIGENCE OR NOT.{" "}
                </b>
              </p>

              <p>
                <b>
                  <u>Express Representations.</u>
                </b>{" "}
                I expressly represent to SpaceX as follows:
              </p>
              <ul>
                <li>My driver’s license has never been suspended;</li>
                <li>
                  I will inspect any SpaceX Tesla before I drive or ride in it,
                  and if I see anything of concern, I will immediately report
                  it;
                </li>
                <li>
                  I fully understand the risks and dangers inherent in driving
                  anywhere and voluntarily assume those risks. I further
                  acknowledge that I have other options for transportation that
                  I am personally and solely choosing not to use;
                </li>
                <li>
                  None of the Releasees have asked me nor required me to drive a
                  SpaceX Tesla for any reason;
                </li>
                <li>
                  I acknowledge and agree that my voluntary decision to drive or
                  ride in any SpaceX Tesla is not in the course and scope of my
                  employment with or for SpaceX or any of the other Releasees;
                </li>
                <li>
                  I voluntarily agree to assume the entire risk of any personal
                  injury, including death, or property damage or other harm or
                  damage which I might cause or suffer as a result of my
                  decision to drive or ride in any SpaceX Tesla; and
                </li>
                <li>
                  I agree that, in the event I am involved in any incident while
                  driving, or otherwise operating or using any Tesla, that none
                  of the Releasees shall be responsible or liable for any
                  damage, injury, or third-party liability that may occur, be
                  related in any way to, or arise in connection with such
                  activity or incident, and that I shall defend, indemnify,
                  release, and hold harmless the Releasees for same.
                </li>
              </ul>

              <p>
                <b>
                  IF ANY PROVISION OF THIS AGREEMENT SHALL BE DECLARED BY A
                  COURT OF COMPETENT JURISDICTION TO BE INVALID OR
                  UNENFORCEABLE, THE REMAINDER OF THIS AGREEMENT SHALL NOT BE
                  AFFECTED THEREBY AND SHALL BE ENFORCED TO THE FULLEST EXTENT
                  PERMITTED BY LAW.
                </b>
              </p>

              <p>
                <b>
                  BY SIGNING BELOW, I VERIFY THAT I HAVE READ, UNDERSTAND, AND
                  AGREE TO THE TERMS AND CONDITIONS STATED HEREIN.
                </b>
              </p>

              <h3
                style={{
                  fontWeight: "400",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  textAlign: "center"
                }}
              >
                EXHIBIT A
              </h3>
              <h3
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  textAlign: "center"
                }}
              >
                <u>TESLA RIDESHARE PROGRAM POLICIES AND PROCEDURES</u>
              </h3>

              <p>
                The following Tesla Rideshare Program Policies and Procedures
                are required to be adhered to all times when driving, using or
                riding in any Telsas owned by Space Explorations Technologies
                Corp. (“SpaceX”):{" "}
              </p>
              <ul>
                <li>Teslas must remain onsite overnight.</li>
                <li>
                  SpaceX-owned Teslas may not replace or take the place of
                  personally owned vehicles.
                </li>
                <li>
                  You are not required nor being asked to drive SpaceX-owned
                  Teslas as part of your job duties for SpaceX.
                </li>
                <li>
                  You can request a Tesla for up to 2 hours for brief personal
                  trips to and from town (e.g., airport, stores). Any request
                  longer than 2 hours will be considered on a case-by-case
                  basis.
                </li>
                <li>
                  Tesla loaner requests are available for permanent SpaceX
                  employees only.
                </li>
                <li>
                  Before any SpaceX Telsa may be driven or ridden in, the Waiver
                  of Liability, Release, Indemnity and Hold Harmless Agreement
                  must be signed and the vehicle must be inspected to avoid
                  being charged for previous damage.
                </li>
                <li>
                  No international trips to Mexico or elsewhere in a SpaceX
                  Telsa are permitted for any reason.
                </li>
                <li>
                  SpaceX employees are only allowed to drive a company Tesla if
                  they have a valid driver’s license, personal automobile
                  insurance that will provide coverage, and a clean driving
                  record for at least one (1) year.
                </li>
                <li>
                  A clean driving record means the employee has not been cited
                  for a traffic violation, held at fault for a vehicle accident
                  or arrested on charges of violating vehicle and/or traffic
                  laws.
                </li>
                <li>
                  SpaceX can assign and revoke access to its Teslas at its sole
                  discretion.
                </li>
                <li>
                  Abide by all traffic laws at all times. The speed limit in the
                  village is 15mph. Please do not drive over medians.
                </li>
                <li>
                  All drivers and passengers are required to wear seatbelts at
                  all times.
                </li>
                <li>
                  If anything appears to be wrong with the Tesla, you are
                  required to notify SpaceX immediately and return it for a
                  maintenance inspection.
                </li>
                <li>
                  Immediately report changes to your driver privileges, such as
                  driver’s license suspension or traffic violations.
                </li>
                <li>
                  No unsafe or unlawful activity is permitted when using,
                  driving or riding in a Tesla at any time.
                </li>
                <li>
                  No texting or any mobile app or phone shall be used while
                  operating Teslas.
                </li>
                <li>
                  Drinking or using controlled substances while driving is
                  strictly prohibited and will not be tolerated under any
                  circumstances.
                </li>
                <li>No off road or beach driving is permitted.</li>
                <li>No unauthorized passengers are permitted in any Teslas.</li>
                <li>
                  You are responsible for the key card and returning it. Loss of
                  key card will result in a charge to you.
                </li>
                <li>
                  All SpaceX owned vehicles must be parked in a charging station
                  or a designated spot.
                </li>
                <li>
                  Eating or drinking inside a Tesla is prohibited for sanitary
                  reasons. These are shared perks for all of us to enjoy. Please
                  wrap the cord around the back of the charging station when
                  unplugging.
                </li>
                <li>
                  Please plug the Tesla in when you return it to Stargate.
                </li>
                <li>
                  Cars are located at Stargate either at a charging station or
                  in the parking lot.
                </li>
              </ul>

              <p>
                <b>
                  <u>Accidents/ Damage Report:</u>
                </b>
                <br />
                In the event of an incident (no matter who is at fault), it is
                your responsibility to report the incident to the Spaceport
                Operations Team and Legal as soon as possible regardless of how
                minor the damage. It is also your responsibility to promptly
                report the incident to your personal insurance carrier who will
                be responsible for your liability and any damage. If you are
                injured please seek medical attention. If property is involved,
                please take pictures of the incident, the individuals involved,
                the conditions of the area, insurance cards of the other drivers
                involved, contact information for witnesses and law enforcement
                authorities or medical providers, and any damage. Please email
                the details of the incident along with the pictures to the
                Spaceport Operations Team and Legal. Reporting these incidents
                are prudent and necessary.
              </p>
            </div>
          </Grid>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default TermsAndConditions;
