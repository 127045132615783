import React from "react";
import "./SpacexFooter.css";

const SpacexFooter = () => {
  return (
    <footer className="SpacexFooter">
      {window.location.pathname !== "/" && (
        <>
          {/* <p>
            <a
              href="https://sharex2016.spacex.corp/sites/boca/SitePages/BocaChicaVillage.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Boca Village Info
            </a>
          </p>
          <p>
            Don't see travel option you need? Head to our general travel site
            (on VPN):{" "}
            <a href="https://travel/" target="_blank" rel="noopener noreferrer">
              https://travel/
            </a>
          </p> */}
        </>
      )}
    </footer>
  );
};

export default SpacexFooter;
