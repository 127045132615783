import React, { useState } from "react";
import "./SpacexLogin.css";
import { isEmailValid } from "../../utils/emailchecker";
import { SPACEX_LOGIN_EMAIL } from "config/constants";
import axios from "axios";
import queryString from "query-string";
import { Button } from "@material-ui/core";

const SpacexLogin = props => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isPosting, setIsPosting] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSaml, setIsSaml] = useState(true);

  const goFocus = e => setError("");

  const query = queryString.parse(props.history.location.search);

  if (query.e) {
    sessionStorage.setItem(SPACEX_LOGIN_EMAIL, query.e);
    sessionStorage.setItem("access_token", query.t);
    props.history.push("/");
    return null;
  }

  const editEmail = val => {
    setEmail(val.trim().toLowerCase());
  };

  const setUser = (token, email) => {
    sessionStorage.setItem(SPACEX_LOGIN_EMAIL, email);
    sessionStorage.setItem("access_token", token);
    props.history.push("/");
    return;
  };

  const sendEmail = () => {
    if (isEmailSent) {
      return;
    }

    setIsPosting(true);
    setIsEmailSent(true);

    axios
      .post(`/api/auth/otp`, { email })
      .then(resp => {
        if (resp.data.access_token) {
          setUser(resp.data.access_token, email);
          return;
        }
        setIsEmailSent(true);
        setIsPosting(false);
      })
      .catch(err => {
        setIsEmailSent(false);
        setError("* Invalid email!");
        setIsPosting(false);
      });
  };

  const sendOtp = () => {
    if (!isEmailSent) {
      return;
    }

    setError("");
    if (!otp) {
      setError("Please enter your code number.");
      return;
    }

    setIsPosting(true);
    axios
      .post("/api/auth/token", { email, otp_code: otp })
      .then(resp => {
        if (resp.data.access_token) {
          setUser(resp.data.access_token, email);
          return;
        }
        setIsPosting(false);
      })
      .catch(err => {
        if (err.response.status === 406) {
          return setError("Please check your email");
        }
        setError("Your OTP is wrong or already expired");
        setIsPosting(false);
      });
  };

  const processLogin = e => {
    e.preventDefault();

    if (!email) {
      setError("* Email is required!");
      return;
    }

    if (!isEmailValid(email)) {
      setError("* Invalid email!");
      return;
    }

    if (!email.includes("@spacex") && !email.includes("@flymya.co")) {
      setError("* Sorry! Your email domain is not allowed.");
      return;
    }

    sendEmail();
    sendOtp();
  };

  return (
    <div className="SpacexLogin">
      <main id="main-holder">
        {isSaml && (
          <form className="saml-form">
            <Button
              className="saml-btn"
              color="primary"
              onClick={() => {
                window.location =
                  process.env.REACT_APP_API_URL + "/saml/sx/auth";
                return;
              }}
            >
              Login with SpaceX
            </Button>
            <p className="saml-text">
              or click{" "}
              <span className="saml-span" onClick={() => setIsSaml(false)}>
                here
              </span>{" "}
              to have the OTP emailed to you
            </p>
          </form>
        )}

        {!isSaml && (
          <form id="login-form" onSubmit={processLogin}>
            <input
              type="text"
              name="email"
              id="email-field"
              className="login-form-field"
              placeholder="Log in with your SpaceX email"
              value={email}
              onChange={e => editEmail(e.target.value)}
              onFocus={goFocus}
              readOnly={isEmailSent}
            />
            {isEmailSent ? (
              <input
                type="text"
                name="text"
                id="code-field"
                className="login-form-field"
                value={otp}
                onChange={e => setOtp(e.target.value)}
                onFocus={goFocus}
                placeholder="Enter code from your email."
              />
            ) : null}
            {error ? <p id="login-error-msg">{error}</p> : null}
            <button id="login-form-submit" disabled={isPosting}>
              Login
            </button>
          </form>
        )}
      </main>
    </div>
  );
};

export default SpacexLogin;
