import * as Yup from "yup";

const HovercraftValidationSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.array()
      .transform(function(value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
    mobileNo: Yup.string().required("Required")
  });
};

export default HovercraftValidationSchema;
