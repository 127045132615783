import React from "react";
import "assets/scss/SleepDoorCode.scss";

const HovercraftCancellation = props => {
  return (
    <div className="SleepDoorCode" id="FlyCancellation">
      <div
        className="wrapper"
        style={{ maxWidth: "600px", minHeight: "100vh" }}
      >
        <div className="cancel-header-region">
          <span onClick={() => props.history.push("/")}>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back to
            Main Menu
          </span>
        </div>
        <h2 className="title">Cancellation Confirmed</h2>
        <p className="booking-id">
          Your hovercraft request has been cancelled.
        </p>
      </div>
    </div>
  );
};

export default HovercraftCancellation;
