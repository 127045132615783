import React, { useState } from "react";
import moment from "moment";
import { SPACEX_LOGIN_EMAIL } from "config/constants";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import CheckListIcon from "../../../assets/images/checklist-confirmation.svg";
import FromToArrow from "../../../assets/images/from-to-arrow.svg";

const FlyConfirmation = props => {
  const [flightsInfo] = useState(
    props.location.state && props.location.state.chosenFlight
      ? props.location.state.chosenFlight
      : null
  );

  const formatCityNameShort = cityName => {
    let str = cityName;
    let index = str.indexOf("(");
    let short = str.slice(index + 1, str.length - 1);

    return short;
  };
  const formatCityNameLong = cityName => {
    let str = cityName;
    let index = str.indexOf("(");
    let long = str.slice(0, index - 1);
    return long;
  };
  const formatTime = (date, tz = null) =>
    date
      ? moment(date).format("hh:mm A") +
        (tz && tz.abbr
          ? tz.abbr === "unknown"
            ? ` UTC${tz.offset}`
            : ` ${tz.abbr.toUpperCase()}`
          : "")
      : "";
  const formatDisplayDate = date =>
    date ? moment(date, "YYYY-MM-DD").format("DD MMM YYYY") : "";
  const formatTimeduration = (date1, timezone1, date2, timezone2) => {
    let data1 = date1;
    let data2 = date2;
    let t1 =
      timezone1 && timezone1.abbr
        ? timezone1.abbr === "unknown"
          ? `UTC${timezone1.offset}`
          : `${timezone1.abbr.toUpperCase()}`
        : "";
    let t2 =
      timezone2 && timezone2.abbr
        ? timezone2.abbr === "unknown"
          ? `UTC${timezone2.offset}`
          : `${timezone2.abbr.toUpperCase()}`
        : "";

    //convert to PST Time
    t1 === "CST" && (data1 = moment(date1).subtract(2, "hours"));
    t2 === "CST" && (data2 = moment(date2).subtract(2, "hours"));
    t1 === "EST" && (data1 = moment(date1).subtract(3, "hours"));
    t2 === "EST" && (data2 = moment(date2).subtract(3, "hours"));

    let ms = moment(data2).diff(moment(data1));
    let momentFormat = moment.utc(ms).format("HH:mm:ss");
    let hour = momentFormat.slice(0, 2);
    let min = momentFormat.slice(3, 5);
    let H = hour.charAt(0) === "0" ? hour.slice(1) : hour;
    let M = min.charAt(0) === "0" ? min.slice(1) : min;
    let formatted = `${Number(H) > 0 ? H + "hr " : ""} ${
      Number(M) > 0 ? " " + M + "m" : ""
    }`;
    return formatted;
  };

  return (
    <>
      <SpacexNavbar />
      <div style={{ minHeight: "100vh", backgroundColor: "#273142" }}>
        {flightsInfo ? (
          <>
            <div className="confirmation-upper">
              <img src={CheckListIcon} alt="Confirmation" />
              <span className="title" style={{ color: "#2cafe8" }}>
                Your reservation has been received.
              </span>
              <p
                style={{
                  color: "#fafafa",
                  margin: "30px 10px",
                  textAlign: "center",
                  fontFamily: "Roboto, Myanmar3",
                  fontSize: "14px",
                  fontWeight: "500"
                }}
              >
                Please note that your flight has not been confirmed yet.
                <br />
                You will receive another email when your seat on the flight has
                been confirmed by the travel team.
              </p>
            </div>
            <div
              className="MuiGrid-root confirmation-lower MuiGrid-container"
              style={{ maxWidth: "600px" }}
            >
              {flightsInfo.map((flightInfo, index) => (
                <div
                  className="MuiGrid-root middleregion MuiGrid-item MuiGrid-grid-sm-12"
                  key={index}
                >
                  <div className="detaildiv">
                    <div className="detailheader-region">
                      <span className="detailheader"></span>
                      <br />
                      <span className="flightname"></span>
                    </div>
                    <div className="detailtext-region">
                      <div className="fromtoinfo">
                        <span className="short-name">
                          {formatCityNameShort(flightInfo.from_text)}
                        </span>
                        <span className="long-name">
                          {formatCityNameLong(flightInfo.from_text)}
                        </span>
                      </div>
                      <img src={FromToArrow} alt="Arrow" />
                      <div className="fromtoinfo">
                        <span className="short-name">
                          {" "}
                          {formatCityNameShort(flightInfo.to_text)}
                        </span>
                        <span className="long-name">
                          {" "}
                          {formatCityNameLong(flightInfo.to_text)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="detaildiv" id="detaildiv-datetime">
                    <div className="detailheader-region">
                      <span className="detailheader"> Flight Duration</span>
                      <br />
                      <span className="duration">
                        {formatTimeduration(
                          flightInfo.from_datetime,
                          flightInfo.from_timezone,
                          flightInfo.to_datetime,
                          flightInfo.to_timezone
                        )}
                      </span>
                    </div>
                    <div className="detailtext-region">
                      <div className="durationinfo">
                        <span className="time">
                          {formatTime(
                            flightInfo.from_datetime,
                            flightInfo.from_timezone
                          )}
                        </span>
                        <br />
                        <span className="date">
                          {formatDisplayDate(flightInfo.from_date)}
                        </span>
                        <br />
                        <span className="place">
                          {" "}
                          {formatCityNameLong(flightInfo.from_text)}
                        </span>
                      </div>
                      <div className="durationinfo">
                        <span className="time">
                          {formatTime(
                            flightInfo.to_datetime,
                            flightInfo.to_timezone
                          )}
                        </span>
                        <br />
                        <span className="date">
                          {formatDisplayDate(flightInfo.to_date)}
                        </span>
                        <br />
                        <span className="place">
                          {formatCityNameLong(flightInfo.to_text)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="detaildiv">
                <div className="detailheader-region">
                  <span className="detailheader">Contact Email</span>
                </div>
                <div className="detailtext-region">
                  <span className="contact">
                    {sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
                      ? sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default FlyConfirmation;
