import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SleepModifyCustomerDetailForm from "./SleepModifyCustomerDetailForm";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import moment from "moment";

const SleepModifyInfo = props => {
  const [pageContent] = useState({});
  const [countryOfPassport, setCountryOfPassport] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [editData, setEditData] = useState(null);
  const bookingCode = props.match.params.bookingCode;
  const checkedIn = props.match.params.checkedIn;

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      axios.get(`/api/booking_button/get/${bookingCode}`).then(res => {
        res.data && res.data.reservation && setEditData(res.data.reservation);
      });
      mounted.current = true;
    }
  }, [bookingCode]);

  const handleSleepReservation = data => {
    axios
      .post("/api/booking_button/update", {
        id: data.editId,
        checkin_date: moment(data.checkinDate).format("YYYY-MM-DD"),
        checkout_date: moment(data.checkoutDate).format("YYYY-MM-DD")
      })
      .then(() => {
        props.history.push({
          pathname: "/sleep/modify-confirmation",
          search: window.location.search,
          state: {
            personInfo: {
              old_checkin_date: editData.checkin_date
                ? editData.checkin_date
                : "",
              old_checkout_date:
                editData && editData.checkout_date
                  ? editData.checkout_date
                  : "",
              checkin_date: moment(data.checkinDate).format("YYYY-MM-DD"),
              checkout_date: moment(data.checkoutDate).format("YYYY-MM-DD"),
              first_name:
                editData && editData.first_name ? editData.first_name : "",
              last_name:
                editData && editData.last_name ? editData.last_name : "",
              email: editData && editData.email ? editData.email : "",
              phone: editData && editData.phone ? editData.phone : ""
            }
          }
        });
      })
      .catch(error => {
        console.log(error);
        setErrMsg("We are sorry, your booking is not successful.");
      });
  };

  const handleCountryOfPassport = value => {
    setCountryOfPassport(value);
  };

  return (
    <React.Fragment>
      <SpacexNavbar />
      <SleepModifyCustomerDetailForm
        handleUpdateReservation={handleSleepReservation}
        pageType="customerDetails"
        pageContent={pageContent}
        countryOfPassport={countryOfPassport}
        handleCountryOfPassport={handleCountryOfPassport}
        errMsg={errMsg}
        editData={editData}
        checkedIn={checkedIn}
      />
    </React.Fragment>
  );
};

export default SleepModifyInfo;
