import React from "react";
import "assets/scss/SleepDoorCode.scss";

const SleepCheckoutConfirmation = () => {
  return (
    <div className="SleepDoorCode" id="SleepCancellation">
      <div
        className="wrapper"
        style={{ maxWidth: "600px", minHeight: "100vh" }}
      >
        <h2 className="title">You’ve been successfully checked-out!</h2>
      </div>
    </div>
  );
};

export default SleepCheckoutConfirmation;
