/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Checkbox,
  Radio,
  FormControlLabel
} from "@material-ui/core";
import ConfirmationModal from "./FlyConfirmationModal";
import PhonePrefix from "components/inputs/PhonePrefix";
import {
  SPACEX_LOGIN_EMAIL,
  SPACEX_FAMILY_FRIEND_OPTION
} from "config/constants";
import SelectBoxIcon from "@material-ui/icons/ExpandMore";
import MonthOptionComponent from "../../../components/inputs/MonthOptionComponent";
import DayOptionComponent from "../../../components/inputs/DayOptionComponent";
import YearOptionComponent from "../../../components/inputs/YearOptionComponent";

const FlyCustomerInfoWeb = props => {
  let {
    handleOpenClose,
    handleChange,
    errors,
    touched,
    values,
    showConfirmation,
    setFieldValue,
    handleBlur,
    handleSubmit,
    isBrownsville,
    isLoading,
    submitText,
    errMsg,
    bookingStatus,
    isCharter,
    flightId,
    flightArr,
    isMultiple,
    userData
    // additionalBaggage,
    // handleAdditionalBaggage
  } = props;
  const [showCC, setShowCC] = useState(false);
  const [flightDetail, setFlightDetail] = useState(null);
  const [flag, setFlag] = useState(false);
  const [familyValue, setFamilyValue] = useState("Single Employee Booking");
  const regCheck = /^[0-9.,]*$/;

  useEffect(() => {
    if (!flag && flightArr.length > 0) {
      const getData = flightArr.find(
        val => Number(val.id) === Number(flightId)
      );
      getData && setFlightDetail(getData);
      //setShowShuttle(true);
      setFlag(true);
    }
  }, [flag, flightArr, flightId]);
  const [maxDays, setMaxDays] = useState(31);
  const handleMonthChange = event => {
    const selectedMonth = event.target.value;
    let newMaxDays;
    if (selectedMonth === "02") {
      // February
      newMaxDays = 28; // default to 28 days
      if (values.dobYear && values.dobYear % 4 === 0) {
        // Leap year
        newMaxDays = 29;
      }
    } else if (["04", "06", "09", "11"].includes(selectedMonth)) {
      // April, June, September, November
      newMaxDays = 30;
    } else {
      newMaxDays = 31;
    }
    setMaxDays(newMaxDays); // update state with new value
  };

  return (
    <React.Fragment>
      <form
        style={{
          //position: "fixed", // prevent more space at bottom (can delete this after add more fields)
          overflow: "auto", // just open this after add more fields
          padding: "0",
          margin: "0",
          top: "0px", // after open overflow: "auto", set to "0px" again
          left: "0",
          width: "100%",
          height: "100%",
          minHeight: "100vh",
          backgroundColor: "#273142"
        }}
      >
        <Grid container className="customerContainer">
          <Grid item md={2}>
            &nbsp;
          </Grid>
          {flightDetail && Number(flightDetail.family_friend) === 1 && (
            <Grid item xs={10} sm={10}>
              <Select
                onChange={e => {
                  setFamilyValue(e.target.value);
                  if (e.target.value === SPACEX_FAMILY_FRIEND_OPTION) {
                    setFieldValue("family_friend", 1);
                    //isCharter && setFieldValue("charter", false);
                  } else {
                    setFieldValue("family_friend", 0);
                    //isCharter && setFieldValue("charter", true);
                  }
                }}
                IconComponent={SelectBoxIcon}
                className="familySelect"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                value={familyValue}
              >
                <MenuItem value="Single Employee Booking">
                  Single Employee Booking
                </MenuItem>
                <MenuItem value={SPACEX_FAMILY_FRIEND_OPTION}>
                  {SPACEX_FAMILY_FRIEND_OPTION}
                </MenuItem>
              </Select>
            </Grid>
          )}
          {flightDetail && Number(flightDetail.family_friend) === 1 && (
            <Grid item md={2}>
              &nbsp;
            </Grid>
          )}
          <Grid item xs={8} sm={8}>
            <span className="title" style={{ color: "#ffffff" }}>
              Personal Information
            </span>
          </Grid>
          <Grid item md={2}>
            &nbsp;
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>
          <Grid item sm={8}>
            <Grid container className="contactInfo">
              {!isMultiple && values.family_friend === 0 && (
                <Grid item sm={12}>
                  <div
                    style={{
                      background: "rgba(255, 204, 0, 0.4)",
                      textAlign: "center",
                      padding: "6px 0px",
                      marginTop: "6px",
                      borderRadius: "4px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      border: "1px solid rgb(255, 204, 0)"
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        fontSize: "32px",
                        lineHeight: "29px",
                        width: "32px",
                        height: "32px",
                        padding: "0px 6px 0px 6px",
                        color: "rgb(128, 0, 0)"
                      }}
                    >
                      ⚠
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        color: "rgb(0, 0, 0)",
                        float: "none",
                        lineHeight: "32px",
                        margin: "0px 10px 0px 0px",
                        textAlign: "initial"
                      }}
                    >
                      <div
                        style={{
                          display: "block",
                          fontSize: "12px",
                          lineHeight: "18px"
                        }}
                      >
                        If your name does not match your government-issued ID,
                        don't worry, our team can update it for you.
                        <br />
                        please contact <b>flights@spacex.com</b>&nbsp;after you
                        have completed your booking.
                      </div>
                    </div>
                  </div>
                </Grid>
              )}
              <Grid
                item
                sm={values.family_friend === 0 ? 4 : 6}
                className={
                  errors.firstName && touched.firstName
                    ? "searchErrMessage "
                    : ""
                }
              >
                <span className="fieldTitle" style={{ whiteSpace: "nowrap" }}>
                  First Name
                </span>
                <span className="required">*</span>
                <TextField
                  name="firstName"
                  value={
                    !isMultiple &&
                    userData &&
                    userData.first_name &&
                    userData.last_name
                      ? userData.first_name
                      : values.firstName
                  }
                  className={
                    !isMultiple &&
                    userData &&
                    userData.first_name &&
                    userData.last_name
                      ? "auto-filled"
                      : ""
                  }
                  autoComplete="off"
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                />
                <br />
                {errors.firstName && touched.firstName && (
                  <span className="errMessage">{errors.firstName}</span>
                )}
              </Grid>
              {values.family_friend === 0 && (
                <Grid
                  item
                  sm={4}
                  className={
                    errors.middleName && touched.middleName
                      ? "searchErrMessage "
                      : ""
                  }
                  style={{ paddingLeft: "15px" }}
                >
                  <span className="fieldTitle" style={{ whiteSpace: "nowrap" }}>
                    Middle Name
                  </span>
                  <TextField
                    name="middleName"
                    value={
                      !isMultiple &&
                      userData &&
                      userData.first_name &&
                      userData.middle_name &&
                      userData.last_name
                        ? userData.middle_name
                        : values.middleName
                    }
                    className={
                      !isMultiple &&
                      userData &&
                      userData.first_name &&
                      userData.middle_name &&
                      userData.last_name
                        ? "auto-filled"
                        : ""
                    }
                    autoComplete="off"
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                  />
                </Grid>
              )}
              <Grid
                item
                sm={values.family_friend === 0 ? 4 : 6}
                className={
                  errors.lastName && touched.lastName ? "searchErrMessage " : ""
                }
                style={{ paddingLeft: "15px" }}
              >
                <span className="fieldTitle" style={{ whiteSpace: "nowrap" }}>
                  Last Name
                </span>
                <span className="required">*</span>
                <TextField
                  name="lastName"
                  value={
                    !isMultiple &&
                    userData &&
                    userData.last_name &&
                    userData.first_name
                      ? userData.last_name
                      : values.lastName
                  }
                  className={
                    !isMultiple &&
                    userData &&
                    userData.last_name &&
                    userData.first_name
                      ? "auto-filled"
                      : ""
                  }
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.lastName && touched.lastName && (
                  <span className="errMessage">{errors.lastName}</span>
                )}
              </Grid>
              <Grid item sm={12}>
                <span className="fieldDescripiton">
                  (All fields are required by the TSA and must match the
                  information on your government-issued ID.)
                </span>
              </Grid>
              {values.family_friend === 0 && (
                <Grid container className="genderFields">
                  <Grid
                    item
                    sm={4}
                    className={
                      errors.gender && touched.gender ? "searchErrMessage" : ""
                    }
                  >
                    <span className="fieldTitle">
                      Gender<span className="required">*</span>
                    </span>
                    <Select
                      onChange={e => setFieldValue("gender", e.target.value)}
                      IconComponent={SelectBoxIcon}
                      className="gender"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                      value={values.gender}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="x">X</MenuItem>
                    </Select>
                    {errors.gender && touched.gender && (
                      <span className="errMessage">{errors.gender}</span>
                    )}
                  </Grid>
                </Grid>
              )}
              {values.family_friend === 0 && (
                <Grid
                  item
                  sm={12}
                  className="p-bottom-15 gender-divider"
                ></Grid>
              )}
              <Grid
                item
                sm={!isBrownsville ? 6 : values.family_friend === 0 ? 4 : 6}
              >
                <span className="fieldTitle">Mobile Number</span>
                <span className="required">*</span>
                <Grid container>
                  <Grid
                    item
                    sm={12}
                    className={
                      errors.mobileNo && touched.mobileNo
                        ? "searchErrMessage "
                        : ""
                    }
                  >
                    <PhonePrefix
                      mobileVal={values.mobileNo}
                      handlePhonePrefix={value =>
                        setFieldValue("mobileNo", value)
                      }
                    />
                    {errors.mobileNo && touched.mobileNo && (
                      <span className="errMessage">{errors.mobileNo}</span>
                    )}
                    <span className="fieldDescripiton">+1 (XXX) XXX-XXXX</span>
                    <br />
                    <br />
                  </Grid>
                  {/* <Grid
                    item
                    sm={!isBrownsville ? 9 : values.family_friend === 0 ? 8 : 9}
                    style={{ paddingLeft: "10px" }}
                    className={
                      errors.mobileNo && touched.mobileNo
                        ? "searchErrMessage "
                        : ""
                    }
                  >
                    <TextField
                      name="mobileNo"
                      value={values.mobileNo}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.mobileNo && touched.mobileNo && (
                      <span className="errMessage">{errors.mobileNo}</span>
                    )}
                  </Grid> */}
                </Grid>
              </Grid>

              {isBrownsville && values.family_friend === 0 && (
                <Grid
                  item
                  sm={2}
                  style={{ paddingLeft: "10px" }}
                  className={
                    errors.length_of_stay && touched.length_of_stay
                      ? "searchErrMessage "
                      : ""
                  }
                >
                  <span className="fieldTitle" style={{ fontSize: "13px" }}>
                    Length of Stay
                  </span>
                  <span className="required">*</span>
                  <Grid container>
                    <Grid item sm={12} className="flybaggage_height">
                      <TextField
                        name="length_of_stay"
                        type="number"
                        value={values.length_of_stay}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onWheel={e => e.target.blur()}
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                      {errors.length_of_stay && touched.length_of_stay && (
                        <span className="errMessage">
                          {errors.length_of_stay}
                        </span>
                      )}
                      <br />
                      {!errors.length_of_stay && (
                        <span className="fieldDescripiton">Nights</span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid
                item
                sm={6}
                style={{ paddingLeft: "15px" }}
                className={
                  errors.email && touched.email ? "searchErrMessage " : ""
                }
              >
                <span className="fieldTitle">Email</span>
                <Grid container>
                  <Grid item sm={12} className="email-region">
                    <TextField
                      name="first-email"
                      className="first-email"
                      value={
                        sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
                          ? `${sessionStorage.getItem(SPACEX_LOGIN_EMAIL)}`
                          : ""
                      }
                      autoComplete="off"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    {!showCC && (
                      <Button
                        size="small"
                        className="cc-email"
                        onClick={() => setShowCC(true)}
                      >
                        CC
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {values.family_friend === 0 && (
                <Grid
                  item
                  sm={6}
                  style={{}}
                  className={
                    errors.email && touched.email ? "searchErrMessage " : ""
                  }
                >
                  <span className="fieldTitle">Manager's Email</span>
                  <Grid container>
                    <Grid item sm={12} className="email-region">
                      <TextField
                        name="manager-email"
                        className="manager-email"
                        value={values.manager_email}
                        autoComplete="off"
                        onChange={e =>
                          setFieldValue("manager_email", e.target.value)
                        }
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {showCC && (
                <>
                  <Grid item sm={6} style={{ paddingLeft: "15px" }}>
                    <span className="fieldTitle">CC</span>
                    <TextField
                      name="cc_email"
                      className="second-email"
                      value={values.cc_email}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <br />
                    <span className="fieldDescripiton">
                      updates will also be send to this email address
                    </span>
                  </Grid>
                </>
              )}

              {isCharter && values.family_friend === 0 && (
                <>
                  {showCC && <Grid item sm={6}></Grid>}
                  <Grid item sm={6} className="p-left-15">
                    <span className="fieldTitle">Required Information</span>
                    <Grid container>
                      <Grid
                        item
                        sm={5}
                        className="charter-info-cell-label"
                        style={{ margin: "auto" }}
                      >
                        Date of Birth<span className="required">*</span>
                      </Grid>
                      <Grid
                        item
                        sm={7}
                        className={`charter-info-cell-input ${
                          (errors.dobDay && touched.dobDay) ||
                          (errors.dobMonth && touched.dobMonth) ||
                          (errors.dobYear && touched.dobYear)
                            ? "searchErrMessage"
                            : ""
                        }`}
                      >
                        <Grid container>
                          {/* <TextField
                          name="dob"
                          className="second-email"
                          value={values.dob}
                          placeholder="MM/DD/YYYY"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        /> */}
                          <Grid item sm={5}>
                            <select
                              name="dobMonth"
                              className={
                                values.dobMonth === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              required
                              value={values.dobMonth}
                              onChange={e => {
                                setFieldValue("dobMonth", e.target.value);
                                handleMonthChange(e);
                              }}
                            >
                              <MonthOptionComponent />
                            </select>
                          </Grid>
                          <Grid item sm={3}>
                            <select
                              name="dobDay"
                              value={values.dobDay}
                              onChange={e =>
                                setFieldValue("dobDay", e.target.value)
                              }
                              className={
                                values.dobDay === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              required
                            >
                              <DayOptionComponent dayOption={maxDays} />
                            </select>
                          </Grid>
                          <Grid item sm={4}>
                            <select
                              className={
                                values.dobYear === "0000"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              name="dobYear"
                              value={values.dobYear}
                              onChange={e => {
                                setFieldValue("dobYear", e.target.value);
                              }}
                              onBlur={handleBlur}
                            >
                              <YearOptionComponent />
                            </select>
                          </Grid>
                          {((errors.dobDay && touched.dobDay) ||
                            (errors.dobMonth && touched.dobMonth) ||
                            (errors.dobYear && touched.dobYear)) && (
                            <span className="errMessage">
                              {errors.dobDay ||
                                errors.dobMonth ||
                                errors.dobYear}
                            </span>
                          )}
                        </Grid>

                        {/* <SingleDatePicker
                          id="dob"
                          date={values.dob === "" ? null : values.dob}
                          placeholder="MM/DD/YYYY"
                          focused={dateFocus}
                          onFocusChange={() => setDateFocus(true)}
                          onDateChange={date =>
                            setFieldValue("dob", moment(date))
                          }
                          numberOfMonths={1}
                          navPrev={
                            <div className="arrow-left" id="prevMonth"></div>
                          }
                          navNext={
                            <div className="arrow-right" id="nextMonth"></div>
                          }
                          displayFormat="MM/DD/YYYY"
                          hideKeyboardShortcutsPanel={true}
                          keepOpenOnDateSelect={false}
                          onClose={() => setDateFocus(false)}
                          daySize={30}
                          transitionDuration={0}
                          isOutsideRange={() => false}
                          readOnly
                          renderMonthElement={RenderMonthElement}
                        /> */}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        sm={5}
                        className="charter-info-cell-label"
                        style={{ margin: "auto" }}
                      >
                        Personal Weight<span className="required">*</span>
                      </Grid>
                      <Grid
                        item
                        sm={7}
                        className={`charter-info-cell-input ${
                          errors.weight && touched.weight
                            ? "searchErrMessage"
                            : ""
                        }`}
                      >
                        <TextField
                          name="weight"
                          className="second-email"
                          value={values.weight}
                          placeholder="lbs"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid
                        item
                        sm={5}
                        className="charter-info-cell-label"
                        style={{ margin: "auto" }}
                      >
                        Carry-on Bags<span className="required">*</span>
                      </Grid>
                      <Grid
                        item
                        sm={7}
                        className={`charter-info-cell-input ${
                          errors.carry_on && touched.carry_on
                            ? "searchErrMessage"
                            : ""
                        }`}
                      >
                        <Select
                          onChange={e =>
                            setFieldValue("carry_on", e.target.value)
                          }
                          IconComponent={SelectBoxIcon}
                          className="carry_on_baggage"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                          value={values.carry_on}
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid
                        item
                        sm={5}
                        className="charter-info-cell-label"
                        style={{ margin: "auto" }}
                      >
                        Carry-on Weight<span className="required">*</span>
                      </Grid>
                      <Grid
                        item
                        sm={7}
                        className={`charter-info-cell-input ${
                          errors.carry_on_weight && touched.carry_on_weight
                            ? "searchErrMessage"
                            : ""
                        }`}
                      >
                        <TextField
                          name="carry_on_weight"
                          className="second-email"
                          value={values.carry_on_weight}
                          placeholder="lbs"
                          autoComplete="off"
                          onChange={e => {
                            if (regCheck.test(e.target.value)) {
                              setFieldValue("carry_on_weight", e.target.value);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.carry_on_weight && touched.carry_on_weight && (
                          <span className="errMessage">
                            {errors.carry_on_weight}
                          </span>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid
                        item
                        sm={5}
                        className="charter-info-cell-label"
                        style={{ margin: "auto" }}
                      >
                        Checked Bags<span className="required">*</span>
                      </Grid>
                      <Grid
                        item
                        sm={7}
                        className={`charter-info-cell-input ${
                          errors.checked_bags && touched.checked_bags
                            ? "searchErrMessage"
                            : ""
                        }`}
                      >
                        <Select
                          onChange={e =>
                            setFieldValue("checked_bags", e.target.value)
                          }
                          IconComponent={SelectBoxIcon}
                          className="checked_bags_baggage"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                          value={values.checked_bags}
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid
                        item
                        sm={5}
                        className="charter-info-cell-label"
                        style={{ margin: "auto" }}
                      >
                        Baggage Weight<span className="required">*</span>
                      </Grid>
                      <Grid
                        item
                        sm={7}
                        className={`charter-info-cell-input ${
                          errors.baggage && touched.baggage
                            ? "searchErrMessage"
                            : ""
                        }`}
                      >
                        <TextField
                          name="baggage"
                          className="second-email"
                          value={values.baggage}
                          placeholder="lbs"
                          autoComplete="off"
                          onChange={e => {
                            if (regCheck.test(e.target.value)) {
                              setFieldValue("baggage", e.target.value);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.baggage && touched.baggage && (
                          <span className="errMessage">{errors.baggage}</span>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {values.family_friend === 0 && (
                <>
                  <Grid item sm={12} className="p-bottom-15"></Grid>
                  <Grid
                    item
                    sm={6}
                    className={
                      errors.report && touched.report ? "searchErrMessage " : ""
                    }
                  >
                    <span className="fieldTitle">
                      Who will you report to at your destination?
                    </span>
                    <span className="required">*</span>
                    <TextField
                      name="report"
                      type="text"
                      value={values.report}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.report && touched.report && (
                      <span className="errMessage">{errors.report}</span>
                    )}
                    <br />
                    {!touched.report && (
                      <span className="fieldDescripiton">
                        Your project manager on site (not existing manager)
                      </span>
                    )}
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    style={{ paddingLeft: "15px" }}
                    className={
                      errors.purpose && touched.purpose
                        ? "searchErrMessage "
                        : ""
                    }
                  >
                    <span className="fieldTitle">
                      How does this visit advance SpaceX?
                    </span>
                    <span className="required">*</span>
                    <TextField
                      id="boca-work"
                      name="purpose"
                      type="text"
                      value={values.purpose}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="ex: working on Starship SN5 harness design and build."
                    />
                    {errors.purpose && touched.purpose && (
                      <span className="errMessage">{errors.purpose}</span>
                    )}
                    <br />
                    {!touched.purpose && (
                      <span className="fieldDescripiton">
                        Describe in a brief but complete sentence.
                      </span>
                    )}
                  </Grid>
                  <Grid item sm={12} className="p-bottom-15 fly-divider"></Grid>
                  <Grid container className="medicalFields">
                    <Grid item sm={12} className={""}>
                      <h5>
                        Medical Information
                        {errors.has_medical && touched.has_medical && (
                          <span className="errMessage">
                            {errors.has_medical}
                          </span>
                        )}
                      </h5>
                      <span className="medicalTitle">
                        Do you have any medical conditions, allergies or special
                        needs that the flight crew should be aware of prior to
                        flight? (Yes or No)
                      </span>
                      <span className="required">*</span>
                    </Grid>
                    <Grid item sm={12} className="radio-group">
                      <div className="radio-region">
                        <Radio
                          checked={values.has_medical === "yes" ? true : false}
                          onChange={e =>
                            setFieldValue("has_medical", e.target.value)
                          }
                          value="yes"
                          name="medical-radio"
                        />
                        <span>Yes</span>
                      </div>
                      <div className="radio-region">
                        <Radio
                          checked={values.has_medical === "no" ? true : false}
                          onChange={e =>
                            setFieldValue("has_medical", e.target.value)
                          }
                          value="no"
                          name="medical-radio"
                        />
                        <span>No</span>
                      </div>
                    </Grid>
                    {values.has_medical === "yes" && (
                      <>
                        <Grid item sm={12}>
                          <span className="fieldTitle">
                            If so, please provide a brief summary
                          </span>
                        </Grid>
                        <Grid item sm={6} className="radio-summary">
                          <textarea
                            name="summary"
                            rows={4}
                            className={
                              errors.medical_summary && touched.medical_summary
                                ? "err-summary"
                                : "text-summary"
                            }
                            onChange={e =>
                              setFieldValue("medical_summary", e.target.value)
                            }
                            value={values.medical_summary}
                          ></textarea>
                          {errors.medical_summary &&
                            touched.medical_summary && (
                              <span className="errMessage">
                                {errors.medical_summary}
                              </span>
                            )}
                        </Grid>
                      </>
                    )}
                    <Grid
                      item
                      sm={12}
                      className="p-bottom-15 medical-divider"
                    ></Grid>
                  </Grid>
                  {flightDetail && flightDetail.shuttle_bus === "1" && (
                    <Grid container className="shuttleBusFields">
                      <h5>Interested in shuttle bus service if available</h5>
                      <Grid item sm={12} className="shuttleBox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={values.shuttle_departure}
                              checked={values.shuttle_departure}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox"
                              }}
                              onChange={(e, v) =>
                                setFieldValue("shuttle_departure", v)
                              }
                            />
                          }
                          label="For Departure"
                        ></FormControlLabel>
                      </Grid>
                      <Grid item sm={12} className="shuttleBox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={values.shuttle_arrival}
                              checked={values.shuttle_arrival}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox"
                              }}
                              onChange={(e, v) =>
                                setFieldValue("shuttle_arrival", v)
                              }
                            />
                          }
                          label="For Arrival"
                        ></FormControlLabel>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>

            {values.family_friend === 1 && (
              <>
                <span
                  style={{
                    color: "orange",
                    display: "block",
                    marginBottom: "30px",
                    marginTop: "-20px"
                  }}
                >
                  ⚠️ Please note that the above <strong>does NOT count</strong>{" "}
                  as an employee reservation. If you are planning on travelling
                  you must make a <strong>separate employee reservation</strong>
                  .
                </span>
                <h4 className="familyTitle">Family/Friend Information</h4>
                <span
                  style={{
                    color: "orange",
                    display: "block",
                    marginBottom: "10px"
                  }}
                >
                  ⚠️ Please read the{" "}
                  <a
                    style={{
                      color: "yellow",
                      fontWeight: "bold"
                    }}
                    href="https://spacexgcchigh.sharepoint.us/:w:/s/Jet/Ech7BusD2C1MggKVIshYfYIBc2OsYCNhW3wpnWgrE1Sbyg?e=5b79Or"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Friends & Family Flights FAQ
                  </a>{" "}
                  for a how-to and frequently asked questions about booking
                  flights for your guests.
                </span>
                <span
                  style={{
                    color: "orange",
                    display: "block",
                    marginBottom: "10px"
                  }}
                >
                  ⚠️ Please understand that employee travel will always be
                  prioritized. This means that whenever you book a
                  friends/family flight,&nbsp;
                  <strong>
                    <i>it is never guaranteed</i>
                  </strong>
                  .
                </span>
                <Grid container className="familyFields">
                  <Grid
                    item
                    sm={4}
                    className={
                      errors.family_first_name && touched.family_first_name
                        ? "searchErrMessage"
                        : ""
                    }
                  >
                    <span className="fieldTitle">
                      First Name
                      <span className="required">*</span>
                    </span>
                    <TextField
                      name="family_first_name"
                      type="text"
                      value={values.family_first_name}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Family or Friend first name*"
                    />
                  </Grid>
                  <Grid
                    item
                    sm={4}
                    className={
                      errors.family_middle_name && touched.family_middle_name
                        ? "searchErrMessage"
                        : ""
                    }
                    style={{ paddingLeft: "15px" }}
                  >
                    <span className="fieldTitle">Middle Name</span>
                    <TextField
                      name="family_middle_name"
                      type="text"
                      value={values.family_middle_name}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Family or Friend middle name*"
                    />
                  </Grid>
                  <Grid
                    item
                    sm={4}
                    className={
                      errors.family_last_name && touched.family_last_name
                        ? "searchErrMessage"
                        : ""
                    }
                    style={{ paddingLeft: "15px" }}
                  >
                    <span className="fieldTitle">
                      Last Name
                      <span className="required">*</span>
                    </span>
                    <TextField
                      name="family_last_name"
                      type="text"
                      value={values.family_last_name}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Family or Friend last name*"
                    />
                  </Grid>
                  <Grid item sm={12} style={{ marginTop: "5px" }}>
                    <span className="fieldDescripiton">
                      (All fields are required by the TSA and must match the
                      information on your government-issued ID.)
                    </span>
                  </Grid>
                  <Grid container className="genderFields">
                    <Grid
                      item
                      sm={4}
                      className={
                        errors.family_gender && touched.family_gender
                          ? "searchErrMessage"
                          : ""
                      }
                    >
                      <span className="fieldTitle">
                        Gender<span className="required">*</span>
                      </span>
                      <Select
                        onChange={e =>
                          setFieldValue("family_gender", e.target.value)
                        }
                        IconComponent={SelectBoxIcon}
                        className="family_gender"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                        value={values.family_gender}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="x">X</MenuItem>
                      </Select>
                      {errors.family_gender && touched.family_gender && (
                        <span className="errMessage">
                          {errors.family_gender}
                        </span>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    className="p-bottom-15 gender-divider"
                  ></Grid>
                  <Grid
                    item
                    sm={6}
                    className={
                      errors.family_phone && touched.family_phone
                        ? "searchErrMessage"
                        : ""
                    }
                  >
                    <span className="fieldTitle">
                      Phone number<span className="required">*</span>
                    </span>
                    <Grid container>
                      <Grid item sm={12}>
                        <PhonePrefix
                          mobileVal={values.family_phone}
                          handlePhonePrefix={value =>
                            setFieldValue("family_phone", value)
                          }
                        />
                        {errors.family_phone && touched.family_phone && (
                          <span className="errMessage">
                            {errors.family_phone}
                          </span>
                        )}
                        <span className="fieldDescripiton">
                          +1 (XXX) XXX-XXXX
                        </span>
                      </Grid>
                      {/* <Grid
                        item
                        sm={9}
                        style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        className={
                          errors.family_phone && touched.family_phone
                            ? "searchErrMessage "
                            : ""
                        }
                      >
                        <TextField
                          name="family_phone"
                          value={values.family_phone}
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={6}
                    className={
                      errors.family_email && touched.family_email
                        ? "searchErrMessage"
                        : ""
                    }
                    style={{ paddingLeft: "15px" }}
                  >
                    <span className="fieldTitle">
                      Email<span className="required">*</span>
                    </span>
                    <TextField
                      name="family_email"
                      type="text"
                      value={values.family_email}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Family or Friend email*"
                    />
                  </Grid>
                  <Grid item sm={12} className="p-bottom-15"></Grid>
                  <Grid
                    item
                    sm={6}
                    className={
                      errors.family_relationship && touched.family_relationship
                        ? "searchErrMessage"
                        : ""
                    }
                  >
                    <span className="fieldTitle">
                      Relationship<span className="required">*</span>
                    </span>
                    <TextField
                      name="family_relationship"
                      type="text"
                      value={values.family_relationship}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Relationship*"
                    />
                  </Grid>

                  <Grid item sm={6} className="p-left-15">
                    <span className="fieldTitle">Required Information</span>
                    <Grid container>
                      <Grid
                        item
                        sm={5}
                        className="family-info-cell-label"
                        style={{ margin: "auto" }}
                      >
                        Date of Birth<span className="required">*</span>
                      </Grid>
                      <Grid
                        item
                        sm={7}
                        className={`charter-info-cell-input ${
                          (errors.dobDay && touched.dobDay) ||
                          (errors.dobMonth && touched.dobMonth) ||
                          (errors.dobYear && touched.dobYear)
                            ? "searchErrMessage"
                            : ""
                        }`}
                      >
                        {/* <SingleDatePicker
                          id="dob"
                          date={values.dob === "" ? null : values.dob}
                          placeholder="MM/DD/YYYY"
                          focused={dateFocus}
                          onFocusChange={() => setDateFocus(true)}
                          onDateChange={date =>
                            setFieldValue("dob", moment(date))
                          }
                          numberOfMonths={1}
                          navPrev={
                            <div className="arrow-left" id="prevMonth"></div>
                          }
                          navNext={
                            <div className="arrow-right" id="nextMonth"></div>
                          }
                          displayFormat="MM/DD/YYYY"
                          hideKeyboardShortcutsPanel={true}
                          keepOpenOnDateSelect={false}
                          onClose={() => setDateFocus(false)}
                          daySize={30}
                          transitionDuration={0}
                          isOutsideRange={() => false}
                          readOnly
                          renderMonthElement={RenderMonthElement}
                        /> */}
                        <Grid container>
                          <Grid item sm={5}>
                            <select
                              className={
                                values.dobMonth === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              name="dobMonth"
                              required
                              value={values.dobMonth}
                              onChange={e => {
                                setFieldValue("dobMonth", e.target.value);
                                handleMonthChange(e);
                              }}
                            >
                              <MonthOptionComponent />
                            </select>
                          </Grid>
                          <Grid item sm={3}>
                            <select
                              name="dobDay"
                              value={values.dobDay}
                              onChange={e =>
                                setFieldValue("dobDay", e.target.value)
                              }
                              className={
                                values.dobDay === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              required
                            >
                              <DayOptionComponent dayOption={maxDays} />
                            </select>
                          </Grid>
                          <Grid item sm={4}>
                            <select
                              className={
                                values.dobYear === "0000"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              name="dobYear"
                              value={values.dobYear}
                              onChange={e => {
                                setFieldValue("dobYear", e.target.value);
                              }}
                              onBlur={handleBlur}
                            >
                              <YearOptionComponent />
                            </select>
                          </Grid>
                          {((errors.dobDay && touched.dobDay) ||
                            (errors.dobMonth && touched.dobMonth) ||
                            (errors.dobYear && touched.dobYear)) && (
                            <span className="errMessage">
                              {errors.dobDay ||
                                errors.dobMonth ||
                                errors.dobYear}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {isCharter && (
                      <Grid container>
                        <Grid
                          item
                          sm={5}
                          className="family-info-cell-label"
                          style={{ margin: "auto" }}
                        >
                          Personal Weight<span className="required">*</span>
                        </Grid>
                        <Grid
                          item
                          sm={7}
                          className={`charter-info-cell-input ${
                            errors.weight && touched.weight
                              ? "searchErrMessage"
                              : ""
                          }`}
                        >
                          <TextField
                            name="weight"
                            className="second-email"
                            value={values.weight}
                            placeholder="lbs"
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {isCharter && (
                      <>
                        <Grid container>
                          <Grid
                            item
                            sm={5}
                            className="family-info-cell-label"
                            style={{ margin: "auto" }}
                          >
                            Carry-on Bags<span className="required">*</span>
                          </Grid>
                          <Grid
                            item
                            sm={7}
                            className={`charter-info-cell-input ${
                              errors.carry_on && touched.carry_on
                                ? "searchErrMessage"
                                : ""
                            }`}
                          >
                            <Select
                              onChange={e =>
                                setFieldValue("carry_on", e.target.value)
                              }
                              IconComponent={SelectBoxIcon}
                              className="carry_on_baggage"
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null
                              }}
                              value={values.carry_on}
                            >
                              <MenuItem value="0">0</MenuItem>
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid
                            item
                            sm={5}
                            className="family-info-cell-label"
                            style={{ margin: "auto" }}
                          >
                            Carry-on Weight<span className="required">*</span>
                          </Grid>
                          <Grid
                            item
                            sm={7}
                            className={`charter-info-cell-input ${
                              errors.carry_on_weight && touched.carry_on_weight
                                ? "searchErrMessage"
                                : ""
                            }`}
                          >
                            <TextField
                              name="carry_on_weight"
                              className="second-email"
                              value={values.carry_on_weight}
                              placeholder="lbs"
                              autoComplete="off"
                              onChange={e => {
                                if (regCheck.test(e.target.value)) {
                                  setFieldValue(
                                    "carry_on_weight",
                                    e.target.value
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.carry_on_weight &&
                              touched.carry_on_weight && (
                                <span className="errMessage">
                                  {errors.carry_on_weight}
                                </span>
                              )}
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {isCharter && (
                      <>
                        <Grid container>
                          <Grid
                            item
                            sm={5}
                            className="family-info-cell-label"
                            style={{ margin: "auto" }}
                          >
                            Checked Bags<span className="required">*</span>
                          </Grid>
                          <Grid
                            item
                            sm={7}
                            className={`charter-info-cell-input ${
                              errors.checked_bags && touched.checked_bags
                                ? "searchErrMessage"
                                : ""
                            }`}
                          >
                            <Select
                              onChange={e =>
                                setFieldValue("checked_bags", e.target.value)
                              }
                              IconComponent={SelectBoxIcon}
                              className="checked_bags_baggage"
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null
                              }}
                              value={values.checked_bags}
                            >
                              <MenuItem value="0">0</MenuItem>
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid
                            item
                            sm={5}
                            className="family-info-cell-label"
                            style={{ margin: "auto" }}
                          >
                            Baggage Weight<span className="required">*</span>
                          </Grid>
                          <Grid
                            item
                            sm={7}
                            className={`charter-info-cell-input ${
                              errors.baggage && touched.baggage
                                ? "searchErrMessage"
                                : ""
                            }`}
                          >
                            <TextField
                              name="baggage"
                              className="second-email"
                              value={values.baggage}
                              placeholder="lbs"
                              autoComplete="off"
                              onChange={e => {
                                if (regCheck.test(e.target.value)) {
                                  setFieldValue("baggage", e.target.value);
                                }
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.baggage && touched.baggage && (
                              <span className="errMessage">
                                {errors.baggage}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid item sm={12} className="p-bottom-15 fly-divider"></Grid>
                  <Grid container className="medicalFields">
                    <Grid item sm={12} className={"medicalWrapper"}>
                      <h5>
                        Medical Information
                        {errors.has_medical && touched.has_medical && (
                          <span className="errMessage">
                            {errors.has_medical}
                          </span>
                        )}
                      </h5>
                      <span className="medicalTitle">
                        Do you have any medical conditions, allergies or special
                        needs that the flight crew should be aware of prior to
                        flight? (Yes or No)
                      </span>
                      <span className="required">*</span>
                    </Grid>
                    <Grid item sm={12} className="radio-group">
                      <div className="radio-region">
                        <Radio
                          checked={values.has_medical === "yes" ? true : false}
                          onChange={e =>
                            setFieldValue("has_medical", e.target.value)
                          }
                          value="yes"
                          name="medical-radio"
                        />
                        <span>Yes</span>
                      </div>
                      <div className="radio-region">
                        <Radio
                          checked={values.has_medical === "no" ? true : false}
                          onChange={e =>
                            setFieldValue("has_medical", e.target.value)
                          }
                          value="no"
                          name="medical-radio"
                        />
                        <span>No</span>
                      </div>
                    </Grid>
                    {values.has_medical === "yes" && (
                      <>
                        <Grid item sm={12}>
                          <span className="fieldTitle">
                            If so, please provide a brief summary
                          </span>
                        </Grid>
                        <Grid item sm={6} className="radio-summary">
                          <textarea
                            name="summary"
                            rows={4}
                            className={
                              errors.medical_summary && touched.medical_summary
                                ? "err-summary"
                                : "text-summary"
                            }
                            onChange={e =>
                              setFieldValue("medical_summary", e.target.value)
                            }
                            value={values.medical_summary}
                          ></textarea>
                          {errors.medical_summary &&
                            touched.medical_summary && (
                              <span className="errMessage">
                                {errors.medical_summary}
                              </span>
                            )}
                        </Grid>
                      </>
                    )}
                    <Grid
                      item
                      sm={12}
                      className="p-bottom-15 medical-divider"
                    ></Grid>
                  </Grid>

                  {flightDetail.shuttle_bus === "1" && (
                    <Grid container className="shuttleBusFields">
                      <h5>Interested in shuttle bus service if available</h5>
                      <Grid item sm={12} className="shuttleBox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={values.shuttle_departure}
                              checked={values.shuttle_departure}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox"
                              }}
                              onChange={(e, v) =>
                                setFieldValue("shuttle_departure", v)
                              }
                            />
                          }
                          label="For Departure"
                        ></FormControlLabel>
                      </Grid>
                      <Grid item sm={12} className="shuttleBox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={values.shuttle_arrival}
                              checked={values.shuttle_arrival}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox"
                              }}
                              onChange={(e, v) =>
                                setFieldValue("shuttle_arrival", v)
                              }
                            />
                          }
                          label="For Arrival"
                        ></FormControlLabel>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            {flightDetail && Number(flightDetail.animal) === 1 && (
              <Grid item sm={12} className="animalCheck">
                <Checkbox
                  value={values.animal}
                  checked={values.animal}
                  color="primary"
                  inputProps={{
                    "aria-label": "secondary checkbox"
                  }}
                  onChange={(e, v) => setFieldValue("animal", v)}
                />{" "}
                +1 Service Animal or Pet
              </Grid>
            )}
            {values.animal && (
              <Grid container className="animalFields">
                <Grid
                  item
                  sm={4}
                  className={
                    errors.animal_type && touched.animal_type
                      ? "searchErrMessage"
                      : ""
                  }
                >
                  <span className="fieldTitle">
                    Animal Type<span className="required">*</span>
                  </span>
                  <Select
                    onChange={e => setFieldValue("animal_type", e.target.value)}
                    IconComponent={SelectBoxIcon}
                    className="animalType"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                    value={values.animal_type}
                  >
                    <MenuItem value="cat">Cat</MenuItem>
                    <MenuItem value="dog">Dog</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  sm={4}
                  style={{ paddingLeft: "10px" }}
                  className={
                    errors.animal_size && touched.animal_size
                      ? "searchErrMessage"
                      : ""
                  }
                >
                  <span className="fieldTitle">
                    Animal Size<span className="required">*</span>
                  </span>
                  <Select
                    onChange={e => setFieldValue("animal_size", e.target.value)}
                    IconComponent={SelectBoxIcon}
                    className="animalSize"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                    value={values.animal_size}
                  >
                    <MenuItem value="small">Small</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="large">Large</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  sm={4}
                  style={{ paddingLeft: "10px" }}
                  className={
                    errors.animal_name && touched.animal_name
                      ? "searchErrMessage"
                      : ""
                  }
                >
                  <span className="fieldTitle">
                    Animal Name<span className="required">*</span>
                  </span>
                  <TextField
                    name="animal_name"
                    type="text"
                    value={values.animal_name}
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Animal Name*"
                  />
                </Grid>
                <Grid
                  item
                  sm={4}
                  className={
                    errors.animal_service && touched.animal_service
                      ? "searchErrMessage"
                      : ""
                  }
                >
                  <span className="fieldTitle">
                    Service Animal (Yes/No) <span className="required">*</span>
                  </span>
                  <Select
                    onChange={e =>
                      setFieldValue("animal_service", e.target.value)
                    }
                    IconComponent={SelectBoxIcon}
                    className="animalType"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                    value={values.animal_service}
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  sm={4}
                  className={
                    errors.animal_weight && touched.animal_weight
                      ? "searchErrMessage"
                      : ""
                  }
                  style={{ paddingLeft: "10px" }}
                >
                  <span className="fieldTitle">
                    Animal Weight (lbs) <span className="required">*</span>
                  </span>
                  <TextField
                    name="animal_weight"
                    type="text"
                    value={values.animal_weight}
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Animal Weight*"
                  />
                </Grid>
                {values.animal_service === "yes" && (
                  <Grid
                    item
                    sm={4}
                    className={
                      errors.animal_id && touched.animal_id
                        ? "searchErrMessage"
                        : ""
                    }
                    style={{ paddingLeft: "10px" }}
                  >
                    <span className="fieldTitle">
                      Service Animal ID <span className="required">*</span>
                    </span>
                    <TextField
                      name="animal_id"
                      type="text"
                      value={values.animal_id}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Animal Service ID*"
                    />
                  </Grid>
                )}
                <Grid
                  item
                  sm={6}
                  // className={
                  //   errors.animal_detail && touched.animal_detail
                  //     ? "searchErrMessage"
                  //     : ""
                  // }
                >
                  <span className="fieldTitle">
                    Animal Details
                    {/* <span className="required">*</span> */}
                  </span>
                  <textarea
                    name="animal_detail"
                    rows={4}
                    className="animal-detail"
                    placeholder="service animal or pet details*"
                    onChange={handleChange}
                  ></textarea>
                </Grid>
              </Grid>
            )}

            <Grid item sm={12}>
              <ConfirmationModal
                showConfirmation={showConfirmation}
                handleClose={handleOpenClose}
                isLoading={props.isLoading}
                handleContinue={handleSubmit}
              />

              {errMsg !== "" && (
                <span
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    display: "inline-block",
                    width: "100%",
                    textAlign: "right"
                  }}
                >
                  {errMsg}
                </span>
              )}

              <Button
                id="fl-customer-btn-continue"
                className="btnContinue pull-right flyInfoBtn"
                // onClick={() =>
                //   props.validateForm().then(err => {
                //     console.log(err);
                //     if (Object.keys(err).length === 0) {
                //       props.setFieldValue("showConfirmation", true);
                //     } else {
                //       console.log(err);
                //       props.setTouched(err);
                //     }
                //   })
                // }
                onClick={handleSubmit}
                disabled={isLoading && bookingStatus ? true : false}
              >
                {submitText}{" "}
                {isLoading && bookingStatus && <CircularProgress />}
              </Button>
              <div
                style={{
                  color: "orange",
                  marginTop: "15px",
                  display: "block",
                  marginRight: "15px",
                  fontSize: "15px"
                }}
              >
                {/* {values.family_friend === 1 && (
                  <>
                    Each SpaceX employee is allowed up to 3 guests to travel
                    roundtrip at a time.
                    <br />
                    <br />
                  </>
                )} */}
                <strong>Optional: &nbsp;</strong>
                <br />
                You may wear a mask on the aircraft and inside the airport if
                you so choose.
              </div>
            </Grid>
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default FlyCustomerInfoWeb;
