import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axios from "axios";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

let corsProxy = process.env.REACT_APP_PROXY_SERVER || "";
axios.defaults.baseURL = corsProxy + process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error.response.status === 401) {
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function(config) {
  const token = sessionStorage.getItem("access_token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

class Index extends Component {
  render() {
    return (
      <BrowserRouter>
        <App />
      </BrowserRouter>
    );
  }
}
ReactDOM.render(<Index />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
