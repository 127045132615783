import React from "react";
import "assets/scss/SleepDoorCode.scss";

const SleepDoorCode = ({ history, match }) => {
  return (
    <div className="SleepDoorCode">
      {/* {match.params.bookingId ? (
        <div className="wrapper">
          <h2 className="title">Your Reservation Details</h2>
          <p className="booking-id">
            Booking Code : <span>{match.params.bookingId}</span>
          </p>
          <form onSubmit={goExtend}>
            <label htmlFor="days">
              How many nights would you like to extend?
            </label>
            <input
              type="number"
              className="input-field"
              min="0"
              id="days"
              name="days"
              value={night}
              onChange={e => setNight(e.target.value)}
              disabled={isProcesing}
            />
            <div>
              <input
                type="submit"
                className="btn"
                value="Enter"
                disabled={isProcesing}
              />
            </div>
            {errMsg && <p className="error-msg">{errMsg}</p>}
          </form>
        </div>
      ) : (
        <div className="wrapper">
          <h2 className="title">No Booking Code</h2>
        </div>
      )} */}
      <div className="wrapper">
        <h2 className="title">Check Again at 4pm</h2>
        <p className="booking-id">
          Please note that your room assignment and door code will be ready at
          4pm.
        </p>
      </div>
    </div>
  );
};

export default SleepDoorCode;
