import React, { useEffect, useState } from "react";
import axios from "axios";
import FlyCustomerDetailForm from "pages/spacex/fly/FlyCustomerDetailForm";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import SignaturePage from "../SignaturePage";
import _ from "lodash";
import moment from "moment";

const FlyPersonalInfo = props => {
  const [utm_campaign] = useState(null);
  const [flightId] = useState(
    props.match.params.flightId ? props.match.params.flightId : ""
  );
  const [isBrownsville] = useState(
    props.match.params.isBrownsville && props.match.params.isBrownsville === "1"
  );
  const [showNda, setShowNda] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const [bookingStatus, setBookingStatus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [additionalBaggage, setAdditionalBaggage] = useState(false);
  const [isCharter] = useState(
    props.location.state.isCharter === "1" ? true : false
  );
  const [flightArr, setFlightArr] = useState([]);

  useEffect(() => {
    axios
      .post("/api/airplane/availability", {
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment()
          .add(62, "days")
          .format("YYYY-MM-DD")
      })
      .then(function(response) {
        response.data &&
          !response.data.error &&
          setFlightArr(response.data.flights);

        response.data && response.data.error && setFlightArr([]);
      })
      .catch(function(error) {
        console.log(error);
      });
  }, []);

  const handleSetSignature = signature => {
    handleReservation({ ...bookingData, signature }, false);
  };

  const handleAdditionalBaggage = () =>
    setAdditionalBaggage(!additionalBaggage);

  const shouldShowNda = () =>
    props.location.state &&
    props.location.state.isNda === "1" &&
    showNda === false;

  const handleReservation = (data, storeParams = true) => {
    setBookingStatus(true);

    let params = {
      route_id: flightId,
      name: data.customer_name,
      gender: data.customer_gender,
      first_name: data.customer_first_name,
      middle_name: data.customer_middle_name,
      last_name: data.customer_last_name,
      phone: data.customer_phone,
      email: data.customer_email,
      cc_email: data.cc_email,
      manager_email: data.manager_email,
      report: data.report,
      signature: data.signature || "",
      purpose: data.purpose,
      length_of_stay: data.length_of_stay,
      inventory_weight: data.inventory_weight,
      inventory_dimension_h: data.inventory_dimension_h,
      inventory_dimension_w: data.inventory_dimension_w,
      inventory_dimension_l: data.inventory_dimension_l,
      hazardous: data.hazardous,
      additional_weight: data.additional_weight,
      additional_dimension_h: data.additional_dimension_h,
      additional_dimension_w: data.additional_dimension_w,
      additional_dimension_l: data.additional_dimension_l,
      charter: data.charter,
      dob: `${data.dobYear}-${data.dobMonth}-${data.dobDay}`,
      weight: data.weight,
      baggage: data.checked_bags === "0" ? "0" : data.baggage,
      carry_on: data.carry_on,
      checked_bags: data.checked_bags,
      carry_on_weight: data.carry_on === "0" ? "0" : data.carry_on_weight,
      animal: data.animal,
      animal_type: data.animal_type,
      animal_size: data.animal_size,
      animal_name: data.animal_name,
      animal_service: data.animal_service === "yes" ? true : false,
      animal_id: data.animal_id,
      animal_weight: data.animal_weight,
      animal_detail: data.animal_detail,
      family_friend: data.family_friend,
      family_gender: data.family_gender,
      family_name: `${data.family_first_name} ${data.family_middle_name} ${data.family_last_name}`,
      family_first_name: data.family_first_name,
      family_middle_name: data.family_middle_name,
      family_last_name: data.family_last_name,
      family_relationship: data.family_relationship,
      family_email: data.family_email,
      family_phone: data.family_phone,
      has_medical: data.has_medical,
      medical_summary: data.medical_summary,
      shuttle_departure: data.shuttle_departure,
      shuttle_arrival: data.shuttle_arrival
      //shuttle_bus: data.shuttle_bus !== "" ? data.shuttle_bus : false
    };
    // if (isCharter) {
    //   params["charter"] = isCharter;
    // }

    if (
      data.manager_email !== sessionStorage.getItem("manager_email") &&
      data.manager_email !== ""
    ) {
      sessionStorage.setItem("manager_email", data.manager_email);
    }

    if (storeParams) {
      setBookingData(data);

      if (shouldShowNda()) {
        setShowNda(true);
        return;
      }
    }
    axios
      .post("/api/airplane/book", params)
      .then(response => {
        // if (response.data && response.data.error) {
        //   alert(response.data.message + ". If not bringing carry-on or baggage, please enter '0' in the weight fields.");
        //   setState({
        //     bookingStatus: false
        //   });
        //   return;
        // }

        if (response.data && response.data.error) {
          let message = response.data.message; // Store the original message
          // Check if the message is exactly "Failed to create reservation."
          if (message === "Failed to create reservation") {
            // Append the additional instruction to the message
            message +=
              ".\nIf not bringing carry-on or baggage, please enter '0' in the weight fields.";
          }

          alert(message); // Show the modified message
          setBookingStatus(false);
          return;
        }

        response.data &&
          !response.data.error &&
          response.data.flights &&
          props.history.push({
            pathname: "/fly/confirmation",
            search: window.location.search,
            state: { chosenFlight: response.data.flights }
          });

        let err = [];
        _.forOwn(response.data.errors, (value, key) => {
          err.push(value);
        });
        response.data && response.data.error && setErrMsg(err.join(","));
      })
      .catch(error =>
        setErrMsg("We are sorry, your booking is not successful")
      );
  };

  return (
    <>
      {showNda && (
        <SignaturePage
          handleReservation={handleReservation}
          setSignature={handleSetSignature}
          errMsg={errMsg}
        />
      )}
      <>
        <SpacexNavbar />
        <FlyCustomerDetailForm
          handleReservation={handleReservation}
          pageType="customerDetails"
          utmCampaign={utm_campaign}
          isBrownsville={isBrownsville}
          additionalBaggage={additionalBaggage}
          handleAdditionalBaggage={handleAdditionalBaggage}
          errMsg={errMsg}
          bookingStatus={bookingStatus}
          submitText={
            props.location.state && props.location.state.isNda === "1"
              ? "Continue"
              : "Confirm Flight!"
          }
          isCharter={isCharter}
          flightId={flightId}
          flightArr={flightArr}
          isMultiple={props.location.state.isMultiple}
          userData={props.location.state.userData}
        />
      </>
    </>
  );
};

export default FlyPersonalInfo;
