import React, { useState, useEffect, useRef } from "react";
import { Grid, Button } from "@material-ui/core";
import axios from "axios";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "assets/scss/Car.scss";
import moment from "moment";

const CarPersonalInfo = props => {
  const [isConfirmRedirect, setIsConfirmRedirect] = useState(false);
  const [vehicleUnitId, setVehicleUnitId] = useState(null);
  const [vehicleId, setVehicleId] = useState(null);
  const [vehicleArr, setVehicleArr] = useState([]);
  const [vehicleName, setVehicleName] = useState(null);
  const [dateVal, setDateVal] = useState(null);
  const [dateFocus, setDateFocus] = useState(false);
  const commonGetAvailableVehicle = date => {
    if (date === undefined) setDateVal(moment());
    else setDateVal(date);
    let params =
      date !== undefined
        ? moment(date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
    axios
      .get("/api/vehicles/availability/" + params)
      .then(function(response) {
        response.data &&
          !response.data.error &&
          setVehicleArr(response.data.vehicles);

        response.data && response.data.error && setVehicleArr([]);
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      commonGetAvailableVehicle();
      mounted.current = true;
    }
  }, []);

  if (isConfirmRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: `/car/personal-information/${moment(dateVal).format(
            "YYYY-MM-DD"
          )}/${vehicleId}/${vehicleUnitId}`,
          search: window.location.search,
          state: {
            name: vehicleName,
            startDate: moment(dateVal).format("YYYY-MM-DD")
          }
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <SpacexNavbar />
      <form
        style={{
          // position: "fixed", // prevent more space at bottom (can delete this after add more fields)
          // overflow: "auto", // just open this after add more fields
          padding: "0",
          margin: "0",
          top: "80px", // after open overflow: "auto", set to "0px" again
          left: "0",
          width: "100%",
          height: "100vh",
          backgroundColor: "#273142"
        }}
      >
        <Grid container>
          <Grid item xs={12} className="date-region">
            <div className="date-inner car-date">
              <span style={{ color: "#fff" }}>Date</span>
              <SingleDatePicker
                id="carStartdate"
                date={dateVal}
                placeholder="Search by date"
                focused={dateFocus}
                onFocusChange={() => setDateFocus(true)}
                onDateChange={date => {
                  console.log(date);
                  setDateVal(date.format("YYYY-MM-DD"));
                  commonGetAvailableVehicle(date);
                }}
                numberOfMonths={1}
                navPrev={<div className="arrow-left" id="prevMonth"></div>}
                navNext={<div className="arrow-right" id="nextMonth"></div>}
                displayFormat="YYYY-MM-DD"
                hideKeyboardShortcutsPanel={true}
                keepOpenOnDateSelect={true}
                onClose={() => setDateFocus(false)}
                showDefaultInputIcon
                inputIconPosition="after"
                daySize={30}
                transitionDuration={0}
                readOnly
              />
            </div>
          </Grid>
        </Grid>
        <Grid container className="customerContainer">
          <Grid item xs={12} className="contactMobileTitle">
            <table className="car-list">
              <tbody>
                <tr>
                  <td className="col-car-reserve"></td>
                  <td>VEHICLE</td>
                </tr>
                {vehicleArr && vehicleArr.length === 0 && (
                  <tr style={{ textAlign: "center", height: "100px" }}>
                    <td colSpan="2">
                      <CircularProgress />
                    </td>
                  </tr>
                )}
                {vehicleArr &&
                  vehicleArr.length > 0 &&
                  vehicleArr.map((vehicle, key) => (
                    <tr key={key}>
                      <td className="col-car-reserve">
                        {vehicle.status === "available" ? (
                          <Button
                            type="button"
                            color="primary"
                            className="btnChoose"
                            onClick={() => {
                              console.log(vehicle);
                              setVehicleName(vehicle.name);
                              setVehicleId(vehicle.id);
                              setVehicleUnitId(vehicle.unit_id);
                              setIsConfirmRedirect(true);
                            }}
                          >
                            Reserve
                          </Button>
                        ) : (
                          <span>Not Available</span>
                        )}
                      </td>
                      <td>
                        {vehicle.name}, {vehicle.unit_name}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default CarPersonalInfo;
