import React, { useState, useRef, useEffect } from "react";
import "./SignaturePage.css";

import SignaturePad from "react-signature-canvas";
import SpacexNavbar from "./SpacexNavbar";
import Axios from "axios";

const SignaturePage = props => {
  const API_PATH = "/api/airplane/getNda";

  const canvas = useRef({});
  const ndadiv = useRef({});

  const [isClearable, setIsClearable] = useState(false);
  const [showSignHere, setShowSignHere] = useState(true);
  const [nda, setNda] = useState("");
  useEffect(() => {
    if (ndadiv.current && nda) {
      // let ndaLines = "" + nda;
      // ndaLines = ndaLines.replace("</b>", "</b><br/>");
      ndadiv.current.innerHTML = nda;
    }
  }, [nda]);
  useEffect(() => {
    Axios.get(API_PATH)
      .then(res => res.data && setNda(res.data.data))
      .catch(err => setNda(""));
  }, []);

  const sigCanvasTouched = () => showSignHere && setShowSignHere(false);

  const signatureDrew = () => !isClearable && setIsClearable(true);

  const clearSignature = () => {
    canvas.current.clear();
    setIsClearable(false);
  };

  const submit = () => {
    props.setSignature(canvas.current.toDataURL());
  };

  return (
    <div id="sig-page-wrapper">
      <SpacexNavbar />
      <div className="SignaturePage">
        <div className="sig-wrapper">
          <h2 className="title">This flight requires a signed NDA</h2>
          {/* <span className="desc">
            The operator of this flight requires a signed NDA
          </span> */}
          <div className="terms-row">
            <div className="terms" ref={ndadiv}></div>
          </div>
          <div className="canvas-wrapper">
            <SignaturePad
              ref={canvas}
              canvasProps={{
                className: "signatureCanvas"
              }}
              backgroundColor="rgba(255, 255, 255, 0)"
              onBegin={sigCanvasTouched}
              onEnd={signatureDrew}
            />
            {showSignHere && <span className="sign-here">(Sign Here)</span>}
          </div>

          {props.errMsg !== "" && (
            <span
              style={{
                color: "#fff",
                fontSize: "12px",
                display: "inline-block",
                width: "100%",
                textAlign: "right"
              }}
            >
              {props.errMsg}
            </span>
          )}
          <div className="btn-row">
            <button
              className="sign-btn"
              onClick={clearSignature}
              disabled={!isClearable}
            >
              Clear
            </button>
            <button
              onClick={submit}
              disabled={!isClearable}
              className="sign-btn"
            >
              Confirm Flight
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignaturePage;
