import React from "react";
import moment from "moment";

const YearOptionComponent = ({ type, pageContent }) => {
  let startYear = "";
  let endYear = "";
  let thisYear = moment().format("Y");
  if (type === "exp") {
    startYear = thisYear;
    endYear = Number(startYear) + 20;
  } else {
    startYear = Number(thisYear);
    endYear = Number(thisYear) - 100;
  }

  let result = [
    <option key="0000" value="0000" disabled>
      {pageContent && pageContent.year ? pageContent.year : "Year"}
    </option>
  ];

  if (type === "exp") {
    for (let i = startYear; i <= endYear; i++) {
      result.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
  } else {
    for (let i = startYear; i >= endYear; i--) {
      result.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
  }

  return <React.Fragment>{result}</React.Fragment>;
};

export default YearOptionComponent;
