import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhonePrefix = ({ mobileVal, handlePhonePrefix, disabled }) => {
  return (
    <React.Fragment>
      <ReactPhoneInput
        country={"us"}
        inputClass="MuiInputBase-formControl"
        onChange={value => handlePhonePrefix(value)}
        disabled={disabled ? disabled : false}
        placeholder="+1 (XXX) XXX-XXXX"
        value={mobileVal}
      />
    </React.Fragment>
  );
};

export default PhonePrefix;
