import React, { useState } from "react";
import moment from "moment";
import { SPACEX_LOGIN_EMAIL } from "config/constants";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import CheckListIcon from "../../../assets/images/checklist-confirmation.svg";
import FromToArrow from "../../../assets/images/from-to-arrow.svg";

const HovercraftConfirmation = props => {
  const [hovercraftsInfo] = useState(
    props.location.state && props.location.state.chosenHovercraft
      ? props.location.state.chosenHovercraft
      : null
  );

  const formatTime = (date, tz = null) =>
    date
      ? moment(date).format("hh:mm A") +
        (tz && tz.abbr
          ? tz.abbr === "unknown"
            ? ` UTC${tz.offset}`
            : ` ${tz.abbr.toUpperCase()}`
          : "")
      : "";
  const formatDisplayDate = date =>
    date ? moment(date, "YYYY-MM-DD").format("DD MMM YYYY") : "";
  const formatTimeduration = (date1, date2) => {
    let ms = moment(date2).diff(moment(date1));
    let momentFormat = moment.utc(ms).format("HH:mm:ss");
    let hour = momentFormat.slice(0, 2);
    let min = momentFormat.slice(3, 5);
    let H = hour.charAt(0) === "0" ? hour.slice(1) : hour;
    let M = min.charAt(0) === "0" ? min.slice(1) : min;
    let formatted = `${Number(H) > 0 ? H + "hr " : ""} ${
      Number(M) > 0 ? " " + M + "m" : ""
    }`;
    return formatted;
  };

  return (
    <>
      <SpacexNavbar />
      <div style={{ minHeight: "100vh", backgroundColor: "#273142" }}>
        {hovercraftsInfo ? (
          <>
            <div className="confirmation-upper">
              <img src={CheckListIcon} alt="Confirmation" />
              <span className="title" style={{ color: "#2cafe8" }}>
                Your seat has been received.
              </span>
            </div>
            <div
              className="MuiGrid-root confirmation-lower MuiGrid-container"
              style={{ maxWidth: "600px" }}
            >
              {hovercraftsInfo.map((hovercraftInfo, index) => (
                <div
                  className="MuiGrid-root middleregion MuiGrid-item MuiGrid-grid-sm-12"
                  key={index}
                >
                  <div className="detaildiv">
                    <div className="detailheader-region">
                      <span className="detailheader"></span>
                      <br />
                      <span className="flightname"></span>
                    </div>
                    <div className="detailtext-region">
                      <div className="fromtoinfo">
                        <span className="short-name">
                          {hovercraftInfo.from_text}
                        </span>
                      </div>
                      <img src={FromToArrow} alt="Arrow" />
                      <div className="fromtoinfo">
                        <span className="short-name">
                          {" "}
                          {hovercraftInfo.to_text}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="detaildiv" id="detaildiv-datetime">
                    <div className="detailheader-region">
                      <span className="detailheader"> Trip Duration</span>
                      <br />
                      <span className="duration">
                        {formatTimeduration(
                          hovercraftInfo.from_datetime,
                          hovercraftInfo.to_datetime
                        )}
                      </span>
                    </div>
                    <div className="detailtext-region">
                      <div className="durationinfo">
                        <span className="time">
                          {formatTime(
                            hovercraftInfo.from_datetime,
                            hovercraftInfo.from_timezone
                          )}
                        </span>
                        <br />
                        <span className="date">
                          {formatDisplayDate(hovercraftInfo.from_date)}
                        </span>
                        <br />
                        <span className="place">
                          {" "}
                          {hovercraftInfo.from_text}
                        </span>
                      </div>
                      <div className="durationinfo">
                        <span className="time">
                          {formatTime(
                            hovercraftInfo.to_datetime,
                            hovercraftInfo.to_timezone
                          )}
                        </span>
                        <br />
                        <span className="date">
                          {formatDisplayDate(hovercraftInfo.to_date)}
                        </span>
                        <br />
                        <span className="place">{hovercraftInfo.to_text}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="detaildiv">
                <div className="detailheader-region">
                  <span className="detailheader">Contact Email</span>
                </div>
                <div className="detailtext-region">
                  <span className="contact">
                    {sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
                      ? sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default HovercraftConfirmation;
