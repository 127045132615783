import React from "react";
import { Grid, Button, Modal, Card } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const HovercraftConfirmationModal = ({
  showConfirmation,
  handleClose,
  isLoading,
  handleContinue,
  isInApp,
  reservationApi,
  paymentApi,
  productName,
  customerEmail
}) => {
  return (
    <React.Fragment>
      <Modal open={showConfirmation}>
        <Card className="confirmationBox">
          <Grid item={true} sm={12}>
            <div>
              {!isLoading && (
                <React.Fragment>
                  <span className="confirmationBoxTitle">
                    Are you sure your booking details are correct?
                  </span>

                  <p
                    className={
                      productName === "bus"
                        ? "busConfirmation description"
                        : "description"
                    }
                  >
                    We will send you an e-ticket by email.
                    <br />
                    Please make sure your contact details are correct.
                  </p>
                </React.Fragment>
              )}

              {isLoading && !reservationApi && (
                <span className="confirmationBoxTitle">
                  Creating your reservation...
                  <br />
                  <br />
                </span>
              )}

              {isLoading && reservationApi && !paymentApi && (
                <span className="confirmationBoxTitle">
                  Creating payment for your reservation...
                  <br />
                  <br />
                </span>
              )}

              {isLoading && paymentApi && (
                <span className="confirmationBoxTitle">
                  Waiting for payment confirmation...
                  <br />
                  <br />
                </span>
              )}

              {isLoading && <CircularProgress />}

              {!isLoading && (
                <React.Fragment>
                  <Button
                    className="btnBack"
                    onClick={() => handleClose(false)}
                  >
                    Check Again
                  </Button>
                  <Button
                    className="btnContinue mobileConfirmBtn mobileBusConfirmButton"
                    onClick={handleContinue}
                  >
                    Continue
                  </Button>
                </React.Fragment>
              )}
            </div>
          </Grid>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default HovercraftConfirmationModal;
