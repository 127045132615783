import React from "react";
import { TextField, Grid, Button, CircularProgress } from "@material-ui/core";
import PhonePrefix from "components/inputs/PhonePrefix";
import { SPACEX_LOGIN_EMAIL } from "config/constants";

const HovercraftCustomerInfoMobile = props => {
  let {
    values,
    handleBlur,
    handleChange,
    isLoading,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    submitText,
    errMsg
  } = props;

  return (
    <React.Fragment>
      <Grid
        container
        style={{
          //position: "fixed",
          overflow: "auto",
          display: "block",
          padding: "0",
          margin: "0",
          top: "0",
          left: "0",
          width: "100%",
          height: "100vh",
          backgroundColor: "#273142"
        }}
      >
        <Grid
          item
          xs={12}
          className="contactMobileTitle"
          style={{ backgroundColor: "#273142" }}
        >
          <span
            className="title"
            style={{ paddingLeft: "15px", color: "#fff" }}
          >
            Personal Information
          </span>
        </Grid>

        <Grid item xs={12} className="contactMobile" style={{ margin: "5px" }}>
          <Grid item xs={12} style={{ paddingTop: "15px" }}>
            <TextField
              error={errors.firstName && touched.firstName ? true : false}
              id="first-name"
              name="firstName"
              value={values.firstName}
              onBlur={handleBlur}
              label={"First Name (must match ID)"}
              onChange={handleChange}
              helperText={
                errors.firstName && touched.firstName ? errors.firstName : ""
              }
              autoComplete="off"
              required={true}
            />
          </Grid>
          <Grid item xs={12} style={{}}>
            <TextField
              error={errors.lastName && touched.lastName ? true : false}
              id="last-name"
              name="lastName"
              value={values.lastName}
              label={"Last Name (must match ID)"}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.lastName && touched.lastName ? errors.lastName : ""
              }
              autoComplete="off"
              required={true}
            />
          </Grid>
          <div className="mobileContainer">
            <div className="mobileNoDiv">
              <span
                className={`${
                  errors.mobileNo && touched.mobileNo ? "required" : ""
                }`}
              >
                Mobile Number<span className="required">*</span>
              </span>
              <PhonePrefix
                mobileVal={values.mobileNo}
                handlePhonePrefix={value => setFieldValue("mobileNo", value)}
              />
              {errors.mobileNo && touched.mobileNo && (
                <span className="MuiFormHelperText-root Mui-error">
                  {errors.mobileNo}
                </span>
              )}
              <span className="fieldDescripiton">+1 (XXX) XXX-XXXX</span>
            </div>
          </div>

          <div className="emailContainer mobile-email-region">
            <div className="mobile-first-email">
              <TextField
                error={errors.email && touched.email ? true : false}
                id="first-email"
                name="first-email"
                value={
                  sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
                    ? `${sessionStorage.getItem(SPACEX_LOGIN_EMAIL)}`
                    : ""
                }
                label={"Email"}
                InputProps={{
                  readOnly: true
                }}
                autoComplete="off"
              />
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          className="continueBox"
          style={{
            background: "transparent",
            border: "0px"
          }}
        >
          {errMsg !== "" && (
            <span
              style={{
                color: "#fff",
                fontSize: "12px",
                display: "inline-block",
                width: "100%",
                textAlign: "right"
              }}
            >
              {errMsg}
            </span>
          )}
          <div
            style={{
              color: "orange",
              marginTop: "15px",
              display: "block",
              marginRight: "15px",
              fontSize: "15px"
            }}
          >
            <strong>Optional: &nbsp;</strong>
            <br />
            You may wear a mask on the hovercraft and inside the port if you so
            choose.
          </div>
          <Button
            className="btnContinue pull-right flyInfoBtnMobile"
            id="fl-customer-btn-continue"
            onClick={handleSubmit}
            disabled={isLoading ? true : false}
            style={{ marginBottom: "10px" }}
          >
            {submitText} {isLoading && <CircularProgress />}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default HovercraftCustomerInfoMobile;
