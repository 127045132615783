/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  CircularProgress,
  Select,
  MenuItem
} from "@material-ui/core";
import { isInclusivelyAfterDay, SingleDatePicker } from "react-dates";
import ConfirmationModal from "pages/flight/ConfirmationModal";
import PhonePrefix from "components/inputs/PhonePrefix";
import {
  SLEEP_SINGLE_EMPLOYEE_OPTION,
  SLEEP_FAMILY_FRIEND_OPTION,
  SLEEP_FRIEND_BNB_OPTION
} from "../../../../config/constants";
import SelectBoxIcon from "@material-ui/icons/ExpandMore";
import MonthOptionComponent from "../../../../components/inputs/MonthOptionComponent";
import DayOptionComponent from "../../../../components/inputs/DayOptionComponent";
import YearOptionComponent from "../../../../components/inputs/YearOptionComponent";
import moment from "moment";

const styles = { marginTop: 10 };

const SleepModifyCustomerInfoWeb = ({
  handleOpenClose,
  handleChange,
  values,
  showConfirmation,
  setFieldValue,
  handleBlur,
  handleSubmit,
  errMsg,
  isLoading,
  familyValue,
  checkedIn
}) => {
  const [checkinDateFocus, setCheckinDateFocus] = useState(false);
  const [checkoutDateFocus, setCheckoutDateFocus] = useState(false);
  const [maxDays, setMaxDays] = useState(31);

  const handleMonthChange = event => {
    const selectedMonth = event.target.value;
    let newMaxDays;
    if (selectedMonth === "02") {
      // February
      newMaxDays = 28; // default to 28 days
      if (values.dobYear && values.dobYear % 4 === 0) {
        // Leap year
        newMaxDays = 29;
      }
    } else if (["04", "06", "09", "11"].includes(selectedMonth)) {
      // April, June, September, November
      newMaxDays = 30;
    } else {
      newMaxDays = 31;
    }
    setMaxDays(newMaxDays); // update state with new value
  };

  const isDayBlocked = momentDate => {
    const dayString = momentDate.format("ddd");
    return dayString === "Sat" || dayString === "Sun";
  };

  return (
    <React.Fragment>
      <form
        style={{
          //position: "fixed", // prevent more space at bottom (can delete this after add more fields)
          overflow: "auto", // just open this after add more fields
          padding: "0",
          margin: "0",
          top: "0px", // after open overflow: "auto", set to "0px" again
          left: "0",
          width: "100%",
          height: "100%",
          minHeight: "100vh",
          backgroundColor: "#273142"
        }}
      >
        <Grid
          container
          className="customerContainer sleep-customerinfo sleep-modify-wrapper"
        >
          <Grid item md={2}>
            &nbsp;
          </Grid>

          <Grid item xs={10} sm={10}>
            <Select
              onChange={e => {
                e.target.value === SLEEP_FAMILY_FRIEND_OPTION
                  ? setFieldValue("family_friend", 1)
                  : setFieldValue("family_friend", 0);
              }}
              IconComponent={SelectBoxIcon}
              className="familySelect readonly"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
              value={
                values.family_friend === 0
                  ? SLEEP_SINGLE_EMPLOYEE_OPTION
                  : SLEEP_FAMILY_FRIEND_OPTION
              }
              disabled={true}
            >
              <MenuItem value={SLEEP_SINGLE_EMPLOYEE_OPTION}>
                {SLEEP_SINGLE_EMPLOYEE_OPTION}
              </MenuItem>
              <MenuItem value={SLEEP_FAMILY_FRIEND_OPTION}>
                {SLEEP_FAMILY_FRIEND_OPTION}
              </MenuItem>
              {/* <MenuItem value={SLEEP_FRIEND_BNB_OPTION}>
                {SLEEP_FRIEND_BNB_OPTION}
              </MenuItem> */}
            </Select>
          </Grid>
          <Grid item md={2}>
            &nbsp;
          </Grid>

          <Grid item xs={8} sm={8}>
            <span className="title" style={{ color: "#ffffff" }}>
              {values.family_friend === 0
                ? "Personal Information"
                : "Starbase Host"}
            </span>
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>

          <Grid item md={8}>
            <Grid container className="contactInfo">
              <Grid item sm={12}>
                <span className="fieldTitle" style={{ fontWeight: "bold" }}>
                  Full Name: {values.fullName}
                </span>
              </Grid>
              <Grid item sm={12} className="p-bottom-15"></Grid>
              <Grid item sm={6}>
                <span className="fieldTitle">
                  Mobile Number (required for sms/notification)
                </span>
                <span className="required">*</span>
                <Grid container>
                  <Grid item sm={12}>
                    <PhonePrefix
                      mobileVal={values.mobileNo}
                      handlePhonePrefix={value =>
                        setFieldValue("mobileNo", value)
                      }
                      disabled={true}
                    />
                    <span className="fieldDescripiton">+1 (XXX) XXX-XXXX</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6} style={{ paddingLeft: "15px" }}>
                <span className="fieldTitle">Email</span>
                <span className="required">*</span>
                <TextField
                  name="email"
                  value={values.email}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: true
                  }}
                />
                <br />
                <span className="fieldDescripiton">e.g. email@example.com</span>
              </Grid>

              {values.family_friend === 0 && (
                <DateArea
                  values={values}
                  checkinDateFocus={checkinDateFocus}
                  setCheckinDateFocus={setCheckinDateFocus}
                  setFieldValue={setFieldValue}
                  isDayBlocked={isDayBlocked}
                  checkoutDateFocus={checkoutDateFocus}
                  setCheckoutDateFocus={setCheckoutDateFocus}
                  checkedIn={checkedIn}
                />
              )}

              {values.family_friend === 0 && (
                <Grid item sm={6}>
                  <span className="fieldTitle">
                    Do you work night shift or day shift?
                  </span>
                  <span className="required">*</span>
                  <Select
                    onChange={e => {
                      setFieldValue("avgWork", e.target.value);
                    }}
                    IconComponent={SelectBoxIcon}
                    className="sleep-avg-work"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                    value={values.avgWork}
                    readOnly={true}
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="day shift">day shift</MenuItem>
                    <MenuItem value="night shift">night shift</MenuItem>
                  </Select>
                </Grid>
              )}

              {values.family_friend === 0 && (
                <>
                  {familyValue === SLEEP_FRIEND_BNB_OPTION && (
                    <Grid item sm={6} style={{ paddingLeft: "15px" }}>
                      <span className="fieldTitle">Host spacex.com email</span>
                      <span className="required">*</span>
                      <TextField
                        name="host_spacex_email"
                        type="text"
                        value={values.host_spacex_email}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item sm={12} className="p-bottom-15"></Grid>
                  <Grid item sm={12}>
                    <span className="fieldTitle">
                      Reason for staying on site
                    </span>
                    <span className="required">*</span>
                    <TextField
                      name="requests"
                      type="text"
                      placeholder="Reason for staying on site *"
                      value={values.requests}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            {values.family_friend === 1 && (
              <>
                <h4 className="familyTitle">Traveler to Starbase</h4>
                <Grid container className="familyFields">
                  <Grid item sm={12}>
                    <span className="fieldTitle" style={{ fontWeight: "bold" }}>
                      Family Full Name: {values.family_full_name}
                    </span>
                  </Grid>
                  <Grid item sm={6}></Grid>
                  <Grid item sm={12} className="p-bottom-15"></Grid>
                  <Grid item sm={6}>
                    <span className="fieldTitle">
                      Mobile Number (required for sms/notification)
                      <span className="required">*</span>
                    </span>
                    <Grid container>
                      <Grid item sm={12}>
                        <PhonePrefix
                          mobileVal={values.family_phone}
                          handlePhonePrefix={value =>
                            setFieldValue("family_phone", value)
                          }
                          disabled={true}
                        />
                        <span className="fieldDescripiton">
                          +1 (XXX) XXX-XXXX
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} style={{ paddingLeft: "10px" }}>
                    <span className="fieldTitle">
                      Email
                      <span className="required">*</span>
                    </span>
                    <TextField
                      name="family_email"
                      type="text"
                      value={values.family_email}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Family or Friend email*"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item sm={6}></Grid>
                  <Grid item sm={6} className="p-left-15">
                    <span className="fieldTitle">Required Information</span>
                    <Grid container>
                      <Grid
                        item
                        sm={5}
                        className="family-info-cell-label"
                        style={{ margin: "auto" }}
                      >
                        Date of Birth<span className="required">*</span>
                      </Grid>
                      <Grid item sm={7} className={`charter-info-cell-input`}>
                        <Grid container>
                          <Grid item sm={5}>
                            <select
                              name="dobMonth"
                              className={
                                values.dobMonth === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              required
                              value={values.dobMonth}
                              onChange={e => {
                                setFieldValue("dobMonth", e.target.value);
                                handleMonthChange(e);
                              }}
                              disabled={true}
                            >
                              <MonthOptionComponent />
                            </select>
                          </Grid>
                          <Grid item sm={3}>
                            <select
                              name="dobDay"
                              className={
                                values.dobDay === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              value={values.dobDay}
                              onChange={e =>
                                setFieldValue("dobDay", e.target.value)
                              }
                              required
                              disabled={true}
                            >
                              <DayOptionComponent dayOption={maxDays} />
                            </select>
                          </Grid>
                          <Grid item sm={4}>
                            <select
                              className={
                                values.dobYear === "0000"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              name="dobYear"
                              value={values.dobYear}
                              onChange={e => {
                                setFieldValue("dobYear", e.target.value);
                              }}
                              onBlur={handleBlur}
                              disabled={true}
                            >
                              <YearOptionComponent />
                            </select>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <DateArea
                    values={values}
                    checkinDateFocus={checkinDateFocus}
                    setCheckinDateFocus={setCheckinDateFocus}
                    setFieldValue={setFieldValue}
                    isDayBlocked={isDayBlocked}
                    checkoutDateFocus={checkoutDateFocus}
                    setCheckoutDateFocus={setCheckoutDateFocus}
                    checkedIn={checkedIn}
                  />

                  <Grid item sm={12}>
                    <span className="fieldTitle">
                      Reason for staying on site
                    </span>
                    <span className="required">*</span>
                    <TextField
                      name="requests"
                      type="text"
                      placeholder="Reason for staying on site *"
                      value={values.requests}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid
              container
              className="customerContainer sleepmodify-customerinfo"
            >
              <div className="sleepmodify-warning">
                <div
                  style={{
                    display: "inline-block",
                    fontSize: "32px",
                    lineHeight: "29px",
                    width: "32px",
                    height: "32px",
                    padding: "0px 20px 0px 0px",
                    color: "rgb(128, 0, 0)"
                  }}
                >
                  ⚠
                </div>
                <div
                  style={{
                    display: "inline-block",
                    color: "rgb(0, 0, 0)",
                    float: "none",
                    lineHeight: "32px",
                    margin: "0px 10px 0px 0px",
                    textAlign: "initial"
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      fontSize: "12px",
                      lineHeight: "18px"
                    }}
                  >
                    <aside>
                      Your request to modify the booking is subject to review by
                      our administration team. <br />
                      Please note that this request may be either approved or
                      rejected, and approval is not guaranteed.
                    </aside>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item sm={12} style={styles}>
              <ConfirmationModal
                showConfirmation={showConfirmation}
                handleClose={handleOpenClose}
                isLoading={isLoading}
                handleContinue={handleSubmit}
              />

              {errMsg !== "" && (
                <span
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    display: "inline-block",
                    width: "100%",
                    textAlign: "right"
                  }}
                >
                  {errMsg}
                </span>
              )}

              <Button
                id="sleep-modify-continue"
                className="btnContinue pull-right"
                onClick={handleSubmit}
                disabled={
                  (errMsg !== "" ? true : false) || (isLoading ? true : false)
                }
              >
                {"Request Modification"}
                {(errMsg !== "" ? <CircularProgress /> : "") ||
                  (isLoading ? <CircularProgress /> : "")}
              </Button>
            </Grid>
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

const DateArea = ({
  values,
  checkinDateFocus,
  setCheckinDateFocus,
  setFieldValue,
  isDayBlocked,
  checkoutDateFocus,
  setCheckoutDateFocus,
  checkedIn
}) => (
  <>
    <Grid item sm={12} className="p-bottom-15"></Grid>
    <Grid item sm={6}>
      <span className="fieldTitle">Check-in Date</span>
      <div className="sleep-datepicker">
        <SingleDatePicker
          id="checkinDate"
          date={values.checkinDate}
          placeholder=""
          focused={checkinDateFocus}
          onFocusChange={() => setCheckinDateFocus(true)}
          onDateChange={date => {
            setFieldValue("checkinDate", date);
            setCheckinDateFocus(false);
          }}
          numberOfMonths={1}
          navPrev={<div className="arrow-left" id="prevMonth"></div>}
          navNext={<div className="arrow-right" id="nextMonth"></div>}
          displayFormat="ddd, DD MMM YYYY"
          hideKeyboardShortcutsPanel={true}
          keepOpenOnDateSelect={true}
          onClose={() => setCheckinDateFocus(false)}
          showDefaultInputIcon
          inputIconPosition="after"
          daySize={30}
          transitionDuration={0}
          readOnly
          isOutsideRange={day =>
            !isInclusivelyAfterDay(day, moment().add(0, "days"))
          }
          isDayBlocked={isDayBlocked}
          disabled={checkedIn === "1" ? true : false}
        />
      </div>
    </Grid>
    <Grid item sm={6} style={{ paddingLeft: "15px" }}>
      <span className="fieldTitle">Check-out Date</span>
      <div className="sleep-datepicker">
        <SingleDatePicker
          id="checkoutDate"
          date={values.checkoutDate}
          placeholder=""
          focused={checkoutDateFocus}
          onFocusChange={() => setCheckoutDateFocus(true)}
          onDateChange={date => {
            setFieldValue("checkoutDate", date);
            setCheckoutDateFocus(false);
          }}
          numberOfMonths={1}
          navPrev={<div className="arrow-left" id="prevMonth"></div>}
          navNext={<div className="arrow-right" id="nextMonth"></div>}
          displayFormat="ddd, DD MMM YYYY"
          hideKeyboardShortcutsPanel={true}
          keepOpenOnDateSelect={true}
          onClose={() => setCheckoutDateFocus(false)}
          showDefaultInputIcon
          inputIconPosition="after"
          daySize={30}
          transitionDuration={0}
          readOnly
          isOutsideRange={day =>
            !isInclusivelyAfterDay(day, values.checkinDate)
          }
          isDayBlocked={isDayBlocked}
        />
      </div>
    </Grid>
    <Grid item sm={12} className="p-bottom-15"></Grid>
  </>
);

export default SleepModifyCustomerInfoWeb;
