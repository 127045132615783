import React from "react";
import MediaQuery from "react-responsive";
import CarCustomerInfoWeb from "pages/spacex/cars/CarCustomerInfoWeb";
import CarCustomerInfoMobile from "pages/spacex/cars/CarCustomerInfoMobile";
import { withRouter } from "react-router";
import { withFormik } from "formik";
import CarCustomerValidationSchema from "pages/spacex/cars/CarCustomerValidationSchema.js";
import { compose } from "recompose";
import { SPACEX_LOGIN_EMAIL } from "config/constants";

const MyForm = props => {
  const handleOpenClose = value => {
    props.setFieldValue("showConfirmation", value);
  };

  return (
    <React.Fragment>
      <MediaQuery minWidth={1025}>
        <CarCustomerInfoWeb
          pageName={props.values.pageName}
          {...props}
          handleOpenClose={handleOpenClose}
          showConfirmation={props.values.showConfirmation}
          isLoading={props.values.isLoading}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <CarCustomerInfoMobile
          {...props}
          handleOpenClose={handleOpenClose}
          showConfirmation={props.values.showConfirmation}
          isLoading={props.values.isLoading}
          isContinue={props.isContinue}
        />
      </MediaQuery>
    </React.Fragment>
  );
};

const normalizeData = values => {
  console.log(values);
  let dataResult = { data: {} };
  dataResult.data["employee_name"] = values.employee_name;
  dataResult.data["email"] = values.email;
  dataResult.data["phone"] = values.phone;
  dataResult.data["notes"] = values.notes;
  dataResult.data["name"] = values.name;
  dataResult.data["pickup_location"] = values.pickup_location;
  dataResult.data["dropoff_location"] = values.dropoff_location;
  dataResult.data["reason"] = values.reason;
  dataResult.data["startDate"] = values.startDate;
  dataResult.data["startTime"] = values.startTime;
  dataResult.data["est_return_date"] = values.est_return_date;
  return dataResult;
};

const getLastData = () => JSON.parse(sessionStorage.getItem("last"));
// const getName = lastData =>
//   lastData && lastData.name ? lastData.name.split(" ") : [];
// const getFirstName = name =>
//   name.length >= 2 ? name.slice(0, -1).join(" ") : name.slice(-1).join(" ");
// const getLastName = name => (name.length < 2 ? "" : name.slice(-1).join(" "));

const CarCustomerDetailForm = compose(
  withRouter,
  withFormik({
    mapPropsToValues: props => {
      let last = getLastData();
      //let name = getName(last);
      let phone = last && last.phone ? last.phone : "";
      let getPhone = phone.charAt(0);
      phone = Number(getPhone) === 1 ? phone.substring(1) : phone;

      return {
        employee_name: props.employee_name,
        phone: props.phone,
        vehicle_id: props.vehicleId,
        unit_id: props.unitId,
        dropoff_location: props.dropoff_location,
        pickup_location: props.pickup_location,
        email: sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
          ? sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
          : "",
        isChecked: false,
        showConfirmation: false,
        est_return_date: props.est_return_date
      };
    },

    validationSchema: props => CarCustomerValidationSchema(props),

    handleSubmit: (values, props) => {
      console.log("values", values);
      props.setFieldValue("isLoading", true);
      let data = normalizeData(values);
      props.props.handleReservation(data.data);
    },

    displayName: "BasicForm"
  })
)(MyForm);

export default CarCustomerDetailForm;
