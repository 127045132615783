import React from "react";
import "./SpacexWrapper.css";
import SpacexNavbar from "./SpacexNavbar";
import SpacexFooter from "./SpacexFooter";

const SpacexWrapper = ({ children }) => {
  return (
    <div className="SpacexWrapper">
      <SpacexNavbar />
      {children}
      <SpacexFooter />
    </div>
  );
};

export default SpacexWrapper;
