/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  CircularProgress,
  Select
} from "@material-ui/core";
import PhonePrefix from "components/inputs/PhonePrefix";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  SLEEP_SINGLE_EMPLOYEE_OPTION,
  SLEEP_FAMILY_FRIEND_OPTION,
  SLEEP_FRIEND_BNB_OPTION
} from "../../../../config/constants";
import MonthOptionComponent from "../../../../components/inputs/MonthOptionComponent";
import DayOptionComponent from "../../../../components/inputs/DayOptionComponent";
import YearOptionComponent from "../../../../components/inputs/YearOptionComponent";
import { isInclusivelyAfterDay, SingleDatePicker } from "react-dates";
import moment from "moment";

const SleepCustomerInfoMobile = ({
  countryFile,
  values,
  handleBlur,
  handleChange,
  isLoading,
  handleSubmit,
  errors,
  touched,
  setFieldValue,
  errMsg,
  familyValue,
  checkedIn
}) => {
  const [checkinDateFocus, setCheckinDateFocus] = useState(false);
  const [checkoutDateFocus, setCheckoutDateFocus] = useState(false);
  const [maxDays, setMaxDays] = useState(31);

  const handleMonthChange = value => {
    const selectedMonth = value;
    let newMaxDays;
    if (selectedMonth === "02") {
      // February
      newMaxDays = 28; // default to 28 days
      if (values.dobYear && values.dobYear % 4 === 0) {
        // Leap year
        newMaxDays = 29;
      }
    } else if (["04", "06", "09", "11"].includes(selectedMonth)) {
      // April, June, September, November
      newMaxDays = 30;
    } else {
      newMaxDays = 31;
    }
    setMaxDays(newMaxDays); // update state with new value
  };

  const isDayBlocked = momentDate => {
    const dayString = momentDate.format("ddd");
    return dayString === "Sat" || dayString === "Sun";
  };

  return (
    <React.Fragment>
      <Grid
        container
        style={{
          //position: "fixed",
          overflow: "auto",
          display: "block",
          padding: "0",
          margin: "0",
          top: "0",
          left: "0",
          width: "100%",
          height: "100vh",
          backgroundColor: "#273142"
        }}
        className="sleep-modify-wrapper"
      >
        <Grid item xs={12} className="familySelectMobile">
          <Select
            native
            value={
              values.family_friend === 0
                ? SLEEP_SINGLE_EMPLOYEE_OPTION
                : SLEEP_FAMILY_FRIEND_OPTION
            }
            onChange={e => {
              e.target.value === SLEEP_FAMILY_FRIEND_OPTION
                ? setFieldValue("family_friend", 1)
                : setFieldValue("family_friend", 0);
            }}
            onBlur={handleBlur}
            inputProps={{
              className: "familySelect readonly"
            }}
            IconComponent={ExpandMoreIcon}
            disabled={true}
          >
            <option value={SLEEP_SINGLE_EMPLOYEE_OPTION}>
              {SLEEP_SINGLE_EMPLOYEE_OPTION}
            </option>
            <option value={SLEEP_FAMILY_FRIEND_OPTION}>
              {SLEEP_FAMILY_FRIEND_OPTION}
            </option>
          </Select>
        </Grid>
        <Grid
          item
          xs={12}
          className="contactMobileTitle"
          style={{ backgroundColor: "#273142" }}
        >
          <span
            className="title"
            style={{ paddingLeft: "15px", color: "#fff" }}
          >
            {values.family_friend === 0
              ? "Personal Information"
              : "Starbase Host"}
          </span>
        </Grid>

        <Grid
          item
          xs={12}
          className="contactMobile car-personal-info "
          style={{ margin: "5px" }}
        >
          <Grid item xs={12}>
            <span className="sleep-modify-fullname">
              Full Name: {values.fullName}
            </span>
          </Grid>
          <div className="mobileContainer">
            <div className="mobileNoDiv">
              <span
                className={`${
                  errors.mobileNo && touched.mobileNo ? "required" : ""
                }`}
              >
                Mobile Number (required for sms/notification)
                <span className="required">*</span>
              </span>
              <PhonePrefix
                mobileVal={values.mobileNo}
                handlePhonePrefix={value => setFieldValue("mobileNo", value)}
                disabled={true}
              />
              <span className="fieldDescripiton">+1 (XXX) XXX-XXXX</span>
            </div>
          </div>
          <TextField
            error={errors.email && touched.email ? true : false}
            id="standard-name"
            name="email"
            value={values.email}
            label={"Email"}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={errors.email && touched.email ? errors.email : ""}
            autoComplete="off"
            required={true}
            InputProps={{
              readOnly: true
            }}
          />

          {values.family_friend === 0 && (
            <DateArea
              values={values}
              checkinDateFocus={checkinDateFocus}
              setCheckinDateFocus={setCheckinDateFocus}
              setFieldValue={setFieldValue}
              isDayBlocked={isDayBlocked}
              checkoutDateFocus={checkoutDateFocus}
              setCheckoutDateFocus={setCheckoutDateFocus}
              checkedIn={checkedIn}
            />
          )}

          {values.family_friend === 0 && (
            <>
              <span
                className={`${
                  errors.avgWork && touched.avgWork
                    ? "required sleep-avg-work-label"
                    : "sleep-avg-work-label"
                }`}
              >
                Do you work night shift or day shift?
                <span className="required">*</span>
              </span>
              <Select
                native
                value={values.avgWork}
                onChange={e => {
                  setFieldValue("avgWork", e.target.value);
                }}
                onBlur={handleBlur}
                IconComponent={ExpandMoreIcon}
                readOnly={true}
                className={
                  errors.avgWork && touched.avgWork
                    ? "sleep-avg-work-mobile avg-error"
                    : "sleep-avg-work-mobile"
                }
              >
                <option value=""></option>
                <option value="day shift">day shift</option>
                <option value="night shift">night shift</option>
              </Select>

              {familyValue === SLEEP_FRIEND_BNB_OPTION && (
                <div className="housing_mobile">
                  <TextField
                    error={
                      errors.host_spacex_email && touched.host_spacex_email
                        ? true
                        : false
                    }
                    id="standard-name"
                    name="host_spacex_email"
                    value={values.host_spacex_email}
                    onBlur={handleBlur}
                    label={"Host spacex.com email"}
                    onChange={handleChange}
                    autoComplete="off"
                    helperText={
                      errors.host_spacex_email && touched.host_spacex_email
                        ? errors.host_spacex_email
                        : ""
                    }
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </div>
              )}
              <TextField
                name="requests"
                className="notes_mobile"
                value={values.requests}
                onBlur={handleBlur}
                label={"Reason for staying on site *"}
                onChange={handleChange}
                placeholder="Reason for staying on site *"
                InputProps={{
                  readOnly: true
                }}
              />
            </>
          )}
        </Grid>

        {values.family_friend === 1 && (
          <>
            <h4 className="familyTitle mobile">Traveler to Starbase</h4>
            <Grid container className="familyFieldsMobile contactMobile">
              <Grid
                item
                xs={12}
                className={
                  errors.family_name && touched.family_name
                    ? "searchErrMessage"
                    : ""
                }
              >
                <span className="sleep-modify-fullname">
                  Family Full Name: {values.family_full_name}
                </span>
              </Grid>

              <div
                className={`${
                  errors.family_phone && touched.family_phone
                    ? "searchErrMessage"
                    : ""
                } mobileContainer`}
              >
                <div className="mobileNoDiv">
                  <span>
                    Mobile Number (required for sms/notification)
                    <span className="required">*</span>
                  </span>
                  <PhonePrefix
                    mobileVal={values.family_phone}
                    handlePhonePrefix={value =>
                      setFieldValue("family_phone", value)
                    }
                    disabled={true}
                  />
                  <span className="fieldDescripiton">+1 (XXX) XXX-XXXX</span>
                </div>
              </div>
              <Grid
                item
                xs={12}
                className={
                  errors.family_email && touched.family_email
                    ? "searchErrMessage"
                    : ""
                }
              >
                <TextField
                  error={
                    errors.family_email && touched.family_email ? true : false
                  }
                  name="family_email"
                  type="text"
                  value={values.family_email}
                  autoComplete="off"
                  label={"Email"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Family or Friend email*"
                  InputProps={{
                    required: true,
                    className: "familyInput",
                    readOnly: true
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: false
                  }}
                />
              </Grid>
              <div className="charter-region-mobile">
                <h4 className="charterTitleMobile">Required Information</h4>
                <span
                  className={`charterDobMobile ${
                    errors.dob && touched.dob ? "required" : ""
                  }`}
                >
                  Date of Birth <span className="required">*</span>
                </span>
                <Grid
                  item
                  xs={12}
                  className={`charter-info-cell-input ${
                    (errors.dobDay && touched.dobDay) ||
                    (errors.dobMonth && touched.dobMonth) ||
                    (errors.dobYear && touched.dobYear)
                      ? "searchErrMessage"
                      : ""
                  }`}
                >
                  <Grid
                    container
                    style={{ paddingBottom: "25px", paddingTop: "5px" }}
                  >
                    <Grid item xs={5}>
                      <select
                        name="dobMonth"
                        className="selectBox"
                        required
                        value={values.dobMonth}
                        onChange={e => {
                          setFieldValue("dobMonth", e.target.value);
                          handleMonthChange(e.target.value);
                        }}
                        disabled={true}
                      >
                        <MonthOptionComponent />
                      </select>
                    </Grid>
                    <Grid item xs={3}>
                      <select
                        name="dobDay"
                        className="selectBox"
                        value={values.dobDay}
                        onChange={e => setFieldValue("dobDay", e.target.value)}
                        required
                        disabled={true}
                      >
                        <DayOptionComponent dayOption={maxDays} />
                      </select>
                    </Grid>
                    <Grid item xs={4}>
                      <select
                        className="selectBox"
                        name="dobYear"
                        value={values.dobYear}
                        onChange={e => {
                          setFieldValue("dobYear", e.target.value);
                          handleMonthChange(values.dobMonth);
                        }}
                        onBlur={handleBlur}
                        disabled={true}
                      >
                        <YearOptionComponent />
                      </select>
                    </Grid>
                    {((errors.dobDay && touched.dobDay) ||
                      (errors.dobMonth && touched.dobMonth) ||
                      (errors.dobYear && touched.dobYear)) && (
                      <span className="errMessage">{errors.dobYear}</span>
                    )}
                  </Grid>
                </Grid>
              </div>

              <DateArea
                values={values}
                checkinDateFocus={checkinDateFocus}
                setCheckinDateFocus={setCheckinDateFocus}
                setFieldValue={setFieldValue}
                isDayBlocked={isDayBlocked}
                checkoutDateFocus={checkoutDateFocus}
                setCheckoutDateFocus={setCheckoutDateFocus}
                checkedIn={checkedIn}
              />

              <TextField
                name="requests"
                className="notes_mobile"
                value={values.requests}
                onBlur={handleBlur}
                label={"Reason for staying on site *"}
                onChange={handleChange}
                placeholder="Reason for staying on site *"
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          </>
        )}

        <Grid container className="customerContainer sleepmodify-customerinfo">
          <div className="sleepmodify-warning">
            <div
              style={{
                display: "inline-block",
                fontSize: "32px",
                lineHeight: "29px",
                width: "32px",
                height: "32px",
                padding: "0px 20px 0px 0px",
                color: "rgb(128, 0, 0)"
              }}
            >
              ⚠
            </div>
            <div
              style={{
                display: "inline-block",
                color: "rgb(0, 0, 0)",
                float: "none",
                lineHeight: "32px",
                margin: "0px 10px 0px 0px",
                textAlign: "initial"
              }}
            >
              <div
                style={{
                  display: "block",
                  fontSize: "12px",
                  lineHeight: "18px"
                }}
              >
                <aside>
                  Your request to modify the booking is subject to review by our
                  administration team. <br />
                  Please note that this request may be either approved or
                  rejected, and approval is not guaranteed.
                </aside>
              </div>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          className="continueBox"
          style={{
            background: "transparent",
            border: "0px"
          }}
        >
          {errMsg !== "" && (
            <span
              style={{
                color: "#fff",
                fontSize: "12px",
                display: "inline-block",
                width: "100%",
                textAlign: "right"
              }}
            >
              {errMsg}
            </span>
          )}
          <Button
            className="btnContinue pull-right"
            id="sleep-modify-continue"
            onClick={handleSubmit}
            disabled={
              (errMsg !== "" ? true : false) || (isLoading ? true : false)
            }
          >
            Request Modification{" "}
            {(errMsg !== "" ? <CircularProgress /> : "") ||
              (isLoading ? <CircularProgress /> : "")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const DateArea = ({
  values,
  checkinDateFocus,
  setCheckinDateFocus,
  setFieldValue,
  isDayBlocked,
  checkoutDateFocus,
  setCheckoutDateFocus,
  checkedIn
}) => (
  <div className="sleepModifyDateMobile">
    <div className="modifyCheckin">
      <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl">
        Check-in Date
      </label>
      <SingleDatePicker
        withPortal
        id="checkinDate"
        date={values.checkinDate}
        placeholder=""
        focused={checkinDateFocus}
        onFocusChange={() => setCheckinDateFocus(true)}
        onDateChange={date => {
          setFieldValue("checkinDate", date);
          setCheckinDateFocus(false);
        }}
        numberOfMonths={1}
        navPrev={<div className="arrow-left" id="prevMonth"></div>}
        navNext={<div className="arrow-right" id="nextMonth"></div>}
        displayFormat="ddd, DD MMM YYYY"
        hideKeyboardShortcutsPanel={true}
        keepOpenOnDateSelect={true}
        onClose={() => setCheckinDateFocus(false)}
        showDefaultInputIcon
        inputIconPosition="after"
        daySize={30}
        transitionDuration={0}
        readOnly
        isOutsideRange={day =>
          !isInclusivelyAfterDay(day, moment().add(0, "days"))
        }
        isDayBlocked={isDayBlocked}
        disabled={checkedIn === "1" ? true : false}
      />
    </div>
    <div className="modifyCheckout">
      <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl">
        Check-out Date
      </label>
      <SingleDatePicker
        withPortal
        id="checkoutDate"
        date={values.checkoutDate}
        placeholder=""
        focused={checkoutDateFocus}
        onFocusChange={() => setCheckoutDateFocus(true)}
        onDateChange={date => {
          setFieldValue("checkoutDate", date);
          setCheckoutDateFocus(false);
        }}
        numberOfMonths={1}
        navPrev={<div className="arrow-left" id="prevMonth"></div>}
        navNext={<div className="arrow-right" id="nextMonth"></div>}
        displayFormat="ddd, DD MMM YYYY"
        hideKeyboardShortcutsPanel={true}
        keepOpenOnDateSelect={true}
        onClose={() => setCheckoutDateFocus(false)}
        showDefaultInputIcon
        inputIconPosition="after"
        daySize={30}
        transitionDuration={0}
        readOnly
        isOutsideRange={day => !isInclusivelyAfterDay(day, values.checkinDate)}
        isDayBlocked={isDayBlocked}
      />
    </div>
  </div>
);

export default SleepCustomerInfoMobile;
