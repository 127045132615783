import React from "react";
import { TextField, Grid, Button, CircularProgress } from "@material-ui/core";
import HovercraftConfirmationModal from "./HovercraftConfirmationModal";
import PhonePrefix from "components/inputs/PhonePrefix";
import { SPACEX_LOGIN_EMAIL } from "config/constants";

const HovercraftCustomerInfoWeb = props => {
  let {
    handleOpenClose,
    handleChange,
    errors,
    touched,
    values,
    showConfirmation,
    setFieldValue,
    handleBlur,
    handleSubmit,
    pageContent,
    isLoading,
    submitText,
    errMsg
  } = props;

  return (
    <React.Fragment>
      <form
        style={{
          //position: "fixed", // prevent more space at bottom (can delete this after add more fields)
          overflow: "auto", // just open this after add more fields
          padding: "0",
          margin: "0",
          top: "0px", // after open overflow: "auto", set to "0px" again
          left: "0",
          width: "100%",
          height: "100%",
          minHeight: "100vh",
          backgroundColor: "#273142"
        }}
      >
        <Grid container className="customerContainer">
          <Grid item md={2}>
            &nbsp;
          </Grid>
          <Grid item xs={8} sm={8}>
            <span className="title" style={{ color: "#ffffff" }}>
              Personal Information
            </span>
          </Grid>
          <Grid item md={2}>
            &nbsp;
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>
          <Grid item sm={8}>
            <Grid container className="contactInfo">
              <Grid
                item
                sm={6}
                className={
                  errors.firstName && touched.firstName
                    ? "searchErrMessage "
                    : ""
                }
              >
                <span className="fieldTitle" style={{ whiteSpace: "nowrap" }}>
                  First Name
                </span>
                <span className="required">*</span>
                <TextField
                  name="firstName"
                  value={values.firstName}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                />
                <br />
                <span className="fieldDescripiton">
                  (Required by TSA - must match government-issued ID)
                </span>
                {errors.firstName && touched.firstName && (
                  <span className="errMessage">{errors.firstName}</span>
                )}
              </Grid>
              <Grid
                item
                sm={6}
                className={
                  errors.lastName && touched.lastName ? "searchErrMessage " : ""
                }
                style={{ paddingLeft: "15px" }}
              >
                <span className="fieldTitle" style={{ whiteSpace: "nowrap" }}>
                  Last Name
                </span>
                <span className="required">*</span>
                <TextField
                  name="lastName"
                  value={values.lastName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="fieldDescripiton">
                  (Required by TSA - must match government-issued ID)
                </span>
                {errors.lastName && touched.lastName && (
                  <span className="errMessage">{errors.lastName}</span>
                )}
              </Grid>
              <Grid item sm={12} className="p-bottom-15"></Grid>
              <Grid item sm={6}>
                <span className="fieldTitle">
                  {pageContent && pageContent.mobileNumberDescription
                    ? pageContent.mobileNumberDescription[0]
                    : "Mobile Number"}
                </span>
                <span className="required">*</span>
                <Grid container>
                  <Grid item sm={12}>
                    <PhonePrefix
                      mobileVal={values.mobileNo}
                      handlePhonePrefix={value =>
                        setFieldValue("mobileNo", value)
                      }
                    />
                    {errors.mobileNo && touched.mobileNo && (
                      <span className="errMessage">{errors.mobileNo}</span>
                    )}
                    <span className="fieldDescripiton">+1 (XXX) XXX-XXXX</span>
                    <br />
                    <br />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={6}
                style={{ paddingLeft: "15px" }}
                className={
                  errors.email && touched.email ? "searchErrMessage " : ""
                }
              >
                <span className="fieldTitle">Email</span>
                <Grid container>
                  <Grid item sm={12} className="email-region">
                    <TextField
                      name="first-email"
                      className="first-email"
                      value={
                        sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
                          ? `${sessionStorage.getItem(SPACEX_LOGIN_EMAIL)}`
                          : ""
                      }
                      autoComplete="off"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={12} className="p-bottom-15"></Grid>
            </Grid>

            <Grid item sm={12}>
              <HovercraftConfirmationModal
                showConfirmation={showConfirmation}
                handleClose={handleOpenClose}
                isLoading={props.isLoading}
                handleContinue={handleSubmit}
              />

              {errMsg !== "" && (
                <span
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    display: "inline-block",
                    width: "100%",
                    textAlign: "right"
                  }}
                >
                  {errMsg}
                </span>
              )}

              <Button
                id="fl-customer-btn-continue"
                className="btnContinue pull-right flyInfoBtn"
                onClick={handleSubmit}
                disabled={isLoading ? true : false}
              >
                {submitText} {isLoading && <CircularProgress />}
              </Button>
              <div
                style={{
                  color: "orange",
                  marginTop: "15px",
                  display: "block",
                  marginRight: "15px",
                  fontSize: "15px"
                }}
              >
                <strong>Optional: &nbsp;</strong>
                <br />
                You may wear a mask on the hovercraft and inside the port if you
                so choose.
              </div>
            </Grid>
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default HovercraftCustomerInfoWeb;
