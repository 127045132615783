import React from "react";
import "./SpacexLogin.css";
import { SPACEX_LOGIN_EMAIL } from "config/constants";
import queryString from "query-string";
import { Button } from "@material-ui/core";

const SAML = ({ history, match }) => {
  const query = queryString.parse(history.location.search);

  if (query.e) {
    sessionStorage.setItem(SPACEX_LOGIN_EMAIL, query.e);
    sessionStorage.setItem("access_token", query.t);
    history.push("/");
    return null;
  }

  return (
    <div className="SpacexLogin">
      <main id="main-holder">
        <h1 id="login-header" style={{ color: "#fff", fontWeight: "700" }}>
          Login
        </h1>
        <Button
          className="bs-search-btn-warning"
          color="primary"
          onClick={() => {
            window.location = process.env.REACT_APP_API_URL + "/saml/sx/auth";
            return;
          }}
        >
          SpaceX
        </Button>
        <Button
          className="bs-search-btn-warning"
          color="primary"
          onClick={() => {
            window.location =
              process.env.REACT_APP_API_URL + "/saml/google/auth";
            return;
          }}
        >
          Google
        </Button>
      </main>
    </div>
  );
};

export default SAML;
