export const IMG_BASE_URL = "https://flymya.com";
export const DATALAYER_URL = "https://booking.flymya.com";

export const EN_LOCALE = "en-US";

//Login Variables
export const SPACEX_LOGIN_EMAIL = "login_email";
export const SPACEX_LOGIN_EXPIRE = "login_expire";
export const SPACEX_MANAGER_EMAIL = "manager_email";

export const SPACEX_BOOKING_BUTTON = "429b67";

export const SPACEX_FAMILY_FRIEND_OPTION = "Family/Friend Booking";

export const SLEEP_SINGLE_EMPLOYEE_OPTION = "Single Employee Booking";
export const SLEEP_FAMILY_FRIEND_OPTION = "Family/Friend Booking";
export const SLEEP_FRIEND_BNB_OPTION = "FriendBNB";
