import * as Yup from "yup";

const CarCustomerValidationSchema = props => {
  return Yup.object().shape({
    employee_name: Yup.string().required("Employee Name is required."),
    phone: Yup.string().required("Phone is required."),
    email: Yup.string()
      .email("Please enter valid email address.")
      .required("Email is required."),
    pickup_location: Yup.string().required("Pickup Location is required."),
    dropoff_location: Yup.string().required("Dropoff Location is required.")
  });
};

export default CarCustomerValidationSchema;
