import React, { useEffect, useState } from "react";
import "assets/scss/SleepDoorCode.scss";
import Axios from "axios";

const SleepCancel = ({ history, match }) => {
  const CHECK_RESERVATION = "/public/reservation/validSleepBooking";
  const [status, setStatus] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const cancelBooking = () => {
    setCancelLoading(true);
    Axios.post("/public/reservation/cancelSleepBooking", {
      bookingCode: match.params.bookingCode
    })
      .then(res => res.data && history.push("/sleep/cancel-confirmation"))
      .catch(err => setStatus("not found"))
      .then(res => setCancelLoading(false));
  };

  useEffect(() => {
    Axios.post(CHECK_RESERVATION, {
      bookingCode: match.params.bookingCode
    })
      .then(res => res.data && setStatus(res.data.status))
      .catch(err => setStatus("not found"));
    //eslint-disable-next-line
  }, []);

  if (!status) {
    return null;
  }

  return (
    <div className="SleepDoorCode" id="SleepCancel">
      <div
        className="wrapper"
        style={{
          maxWidth: "600px",
          marginTop: "0",
          alignSelf: "center",
          minHeight: "100vh"
        }}
      >
        <div className="cancel-header-region">
          <span onClick={() => history.push("/")}>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back to
            Main Menu
          </span>
        </div>
        {status === "not found" ? (
          <h2 className="title">Reservation Not Found</h2>
        ) : (
          <>
            <h2 className="title" style={{ marginTop: "10px" }}>
              Cancel your room
            </h2>
            <p className="booking-id">
              If you would like to cancel your hotel room, click the button
              below.
            </p>
            <button
              className="theme-btn"
              style={{ marginLeft: "0", marginBottom: "30px" }}
              onClick={() => cancelBooking()}
              disabled={cancelLoading}
            >
              Cancel
            </button>
            <br />
          </>
        )}
      </div>
    </div>
  );
};

export default SleepCancel;
